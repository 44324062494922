import Text from '../text'
import styles from './input.module.scss'

interface InputProps {
  type: 'text' | 'password' | 'email' | 'tel' | 'number' | 'textarea'
  label?: string
  placeholder?: string
  value: string | number
  disabled?: boolean
  onChange: (value: string) => void
  adminStyle?: boolean
  className?: string
  shouldAutoFocus?: boolean
}

const Input = ({
  type,
  label,
  value,
  disabled,
  onChange,
  placeholder = '',
  adminStyle,
  className = '',
  shouldAutoFocus,
}: InputProps) => {
  return (
    <>
      {label && <Text value={label} size="head3" className={styles.label} bold />}
      <input
        value={value}
        type={type}
        className={`
        ${styles.input}
        ${adminStyle ? styles.inputAdmin : ''}
        ${className}
        `}
        defaultValue={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        autoFocus={shouldAutoFocus}
      />
    </>
  )
}

export default Input
