const getEnvironment = () => {

    let baseURL = window.location.protocol + '//' + window.location.host + '/api'
    
    if (process.env.JEST_WORKER_ID){
        baseURL = 'https:' + '//' + window.location.host + '/api'
    }
    if (process.env.BASE_URL){
        baseURL = process.env.BASE_URL
    }
    return baseURL
}

export const getSocketEnvironment = () => {

    const baseURL = 'wss://api.dev.sentrylabs.ai/ws/'
    return baseURL
}

export default getEnvironment