import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import { InitialState as ErrorState } from '../error/errorTypes'
import { InitialState as UserState } from '../user/userTypes'
import { InitialState as HistoryUiState } from '../historyUi/historyUiTypes'
import { InitialState as MetricsPeriodState } from '../metricsPeriod/metricsPeriodTypes'
import { InitialState as MetricsState } from '../metrics/metricsTypes'
import { InitialState as TradesState } from '../trades/tradesTypes'
import { InitialState as TradersState } from '../tradersTable/traderTableTypes'
import { InitialState as TradersFilterState } from '../traderTableFilter/traderTableTypes'
import { InitialState as LastReadTokenState } from '../lastReadMessage/lastReadMessageTypes'
import { InitialState as AdminState } from '../admin/lastReadMessageTypes'
import { InitialState as FeedState } from '../superAdmin/superAdminTypes'
import { InitialState as SuperAdminFilterState } from '../superAdminFilter/superAdminFilterTypes'
import { InitialState as TrackState } from '../track/trackTypes'
import errorReducer from '../error/reducer'
import userReducer from '../user/reducer'
import metricsReducer from '../metrics/reducer'
import historyUiReducer from '../historyUi/reducer'
import metricsPeriodReducer from '../metricsPeriod/reducer'
import addAccountMessageReducer from '../accountsChangeMessage/reducer'
import tradesReducer from '../trades/reducer'
import tradersReducer from '../traderTable/reducer'
import traderFilterReducer from '../traderTableFilter/reducer'
import lastReadTokenReducer from '../lastReadMessage/reducer'
import adminReducer from '../admin/reducer'
import sales_filters_reducer from '../salesFilter/reducer'
import current_filter_Reducer from '../currentFilter/reducer'
import feedReducer from '../superAdmin/reducer'
import superAdminTableFilterReducer from '../superAdminFilter/reducer'
import trackReducer from '../track/reducer'

const combined = combineReducers({
  error: errorReducer,
  user: userReducer,
  metrics: metricsReducer,
  historyUi: historyUiReducer,
  metricsPeriod: metricsPeriodReducer,
  addAccount: addAccountMessageReducer,
  trades: tradesReducer,
  lastReadToken: lastReadTokenReducer,
  admin: adminReducer,
  salesFilter: sales_filters_reducer,
  currentFilter: current_filter_Reducer,
  feed: feedReducer,
  superAdminFilter: superAdminTableFilterReducer,
  traders: tradersReducer,
  traderFilter: traderFilterReducer,
  track: trackReducer,
})

export interface Store {
  error: ErrorState
  user: UserState
  historyUi: HistoryUiState
  metricsPeriod: MetricsPeriodState
  metrics: MetricsState
  trades: TradesState
  addAccount: Record<string, string>
  lastReadToken: LastReadTokenState
  isloggedinadmin: AdminState
  feed: FeedState
  superAdminFilter: SuperAdminFilterState
  traders: TradersState
  traderFilter: TradersFilterState
  track: TrackState
}

/*eslint-disable */
const middleware: any[] = [thunk]
/*eslint-enable */

// if (process.env.NODE_ENV === 'development') middleware.push(logger)

const store = createStore(combined, applyMiddleware(...middleware))

export default store
