import produce, { Draft, enableES5 } from 'immer'
import {
  STORE_USER,
  STORE_ACCOUNT_NUMBER,
  SET_SELECTED_ACCOUNT,
  STORE_BROKERS,
  STORE_BROKERS_SERVERS,
  HISTORY_IS_LOADED,
  HISTORY_IS_NOT_LOADED,
  OPEN_ADD_ACCOUNT,
  CLOSE_ADD_ACCOUNT,
  OPEN_REREGISTER_ACCOUNT,
  CLOSE_REREGISTER_ACCOUNT,
  UPDATE_ACCOUNT_ARR,
  POLLING_IN_PROGRESS
} from './actionTypes'
import { InitialState, UserActions } from './userTypes'
enableES5()

export const initial_state: InitialState = {
  logged_in: null,
  account_number: null,
  available_brokers: [],
  available_brokers_servers: [],
  is_history_loaded : false,
  isAddAcount: false,
  isReregisterAcount:false,
  polling_in_progress:[]
}

export default produce((draft: Draft<InitialState>, action: UserActions) => {

  switch (action.type) {
    case STORE_USER: {
      draft.logged_in = action.payload
      draft.account_number = (() => {
        if (typeof draft.account_number === 'string') {
          return draft.account_number
        }

        const accno_for_user = window.localStorage.getItem(`tfx-${draft.logged_in.id}`)

        if (accno_for_user) {
          return accno_for_user
        }
        if (action.payload.accounts[0]) {
          return action.payload.accounts[0].account_number
        }
        return null
      })()
      break
    }

    case STORE_ACCOUNT_NUMBER:     
      draft.account_number = action.payload
      window.localStorage.setItem(`tfx-${draft.logged_in.id}`, `${action.payload}`)
      // @ts-ignore
      draft.logged_in.accounts.push({ account_number: parseInt(action.payload) })
      break

    case SET_SELECTED_ACCOUNT:
      if(action.payload[action.payload.length-1]==='forStaffUserOnly')
      {
        draft.logged_in.currency=action.payload[0]['currency']
        draft.account_number = action.payload[0]['account_number']
        draft.logged_in.accounts = action.payload
      }
      else{
        draft.account_number = action.payload
      }
     
      
      window.localStorage.setItem(`tfx-${draft.logged_in.id}`, `${action.payload}`)
      window.localStorage.setItem('staffLoaded', 'true')
      window.localStorage.setItem('all_data_loaded','true')
      
      break

    case STORE_BROKERS:
      draft.available_brokers = action.payload
      break
    case STORE_BROKERS_SERVERS:
      draft.available_brokers_servers = action.payload
      break
      case HISTORY_IS_NOT_LOADED:
        draft.is_history_loaded = true
        break
      case HISTORY_IS_LOADED:
          draft.is_history_loaded = false
        break
      case OPEN_ADD_ACCOUNT:
         draft.isAddAcount = true
          break
      case CLOSE_ADD_ACCOUNT:
          draft.isAddAcount = false
        break
        case OPEN_REREGISTER_ACCOUNT:
          draft.isReregisterAcount = true
           break
       case CLOSE_REREGISTER_ACCOUNT:
           draft.isReregisterAcount = false
         break
      case UPDATE_ACCOUNT_ARR:
        draft.logged_in.currency='USD'
        draft.logged_in.accounts = action.payload
        draft.account_number = action.payload[0]['account_id']
        window.localStorage.setItem(`tfx-${draft.logged_in.id}`, `${action.payload}`)
        window.localStorage.setItem('all_data_loaded','true')
        // return {...draft}
        break
    
    case POLLING_IN_PROGRESS:
      if (action.payload.remove === undefined) {
        if (draft.polling_in_progress.length === 0)
          draft.polling_in_progress.push(action.payload)
        else {
          var account_found = 0
          for (var i = 0; i < draft.polling_in_progress.length; i++) {
            if (draft.polling_in_progress[i]?.account === action.payload.account) {
              draft.polling_in_progress[i] = action.payload
              account_found++
              break
            }
            
          }
          if(account_found===0)
          {
            draft.polling_in_progress.push(action.payload)
          }
        }
      }
      else{
        var account_detail = []
        for (var i = 0; i < draft.polling_in_progress.length; i++) {
          if (draft.polling_in_progress[i]?.account !== action.payload.account) {
            account_detail.push(draft.polling_in_progress[i])
          }
        } 
        draft.polling_in_progress = account_detail
      }
      break

    default:
      break
  }
}, initial_state)
