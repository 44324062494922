import { CURRENT_FILTER } from './type'

const INITIAL_STATE = null
const current_filter_Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CURRENT_FILTER:
      return {
        ...state,
        current_filter: action.payload,
      }
    default:
      return state
  }
}
export default current_filter_Reducer
