// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1AyuN4nHXc9-mtz-FUt2Tn{position:fixed;z-index:2000;background:#fff;height:100vh;width:100vw}", ""]);
// Exports
exports.locals = {
	"fallbackWrapper": "_1AyuN4nHXc9-mtz-FUt2Tn"
};
module.exports = exports;
