export const STORE_TRADES = 'user/store-trades'
export const STORE_TRADES_PAGINATION = 'user/store-trades-pagination'
export const STORE_DEPOSIT_WITHDRAWALS = 'user/store-deposit-and-withdrawals'
export const STORE_DEPOSIT_WITHDRAWAL_PAGINATION =
  'user/store-deposit-and-withdrawals-pagination'
export const STORE_DEPOSIT_WITHDRAWALS_LOADED =
  'user/store-deposit-and-withdrawals-loaded'
export const STORE_NOTES = 'user/store-notes'
export const STORE_SALES = 'user/store-sales'
export const STORE_TRADES_OPEN = 'user/store-open-trade'
export const STORE_TRADES_DURATION = 'user/store-trade-duration'
export const STORE_TRADES_DURATION_WEEKLY = 'user/store-trade-duration-weekly'
export const STORE_TRADES_DURATION_MONTHLY = 'user/store-trade-duration-monthly'
export const STORE_TRADES_DURATION_ALL = 'user/store-trade-duration-all'