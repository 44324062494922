import { REDIRECT } from '../error/actionTypes'
import {
  FAILED_TO_ADD_ACCOUNT,
  STORE_ACCOUNT_NUMBER,
  SUCCESSFULLY_ADDED_ACCOUNT,
  SUCCESSFULLY_REMOVED_ACCOUNT,
  NO_ACCOUNT_CONNECTED,
  NO_ACCOUNT_CONNECTED_BANNER,
  CLEAR_ACCOUNT_CHANGE_MESSAGE,
} from '../user/actionTypes'
import { FORCE_RELOAD } from './actionTypes'
import { Store as StoreState } from '../store'

interface RedirectToLandingI {
  type: typeof REDIRECT
}

export const redirectToLanding = (): RedirectToLandingI => ({
  type: REDIRECT,
})

export interface FailedToAddAccountI {
  type: typeof FAILED_TO_ADD_ACCOUNT
  payload: string
}

export const failedToAddAccount = (message: string): FailedToAddAccountI => ({
  type: FAILED_TO_ADD_ACCOUNT,
  payload: message,
})

export interface SuccessfullyAddedAccountI {
  type: typeof SUCCESSFULLY_ADDED_ACCOUNT
  payload: string
}

export const successfullyAddedAccount = (message: string): SuccessfullyAddedAccountI => ({
  type: SUCCESSFULLY_ADDED_ACCOUNT,
  payload: message,
})

export const clearAccountChangeMessage = () => ({
  type: CLEAR_ACCOUNT_CHANGE_MESSAGE,
  payload: null,
})

export interface ForceReloadI {
  type: typeof FORCE_RELOAD
  payload: string
}

export const forceReload = (): ForceReloadI => ({
  type: FORCE_RELOAD,
  payload: String(Date.now()),
})

export interface SuccessfullyRemovedAccountI {
  type: typeof SUCCESSFULLY_REMOVED_ACCOUNT
  payload: string
}

export const successfullyRemovedAccount = (): SuccessfullyRemovedAccountI => ({
  type: SUCCESSFULLY_REMOVED_ACCOUNT,
  payload: 'Successfully removed account.',
})

export interface StoreAccountNumberI {
  type: typeof STORE_ACCOUNT_NUMBER
  payload: string
}

export const storeAccountNumber = (account_number: string): StoreAccountNumberI => ({
  type: STORE_ACCOUNT_NUMBER,
  payload: account_number,
})

interface NoAccountConnectedI {
  type: typeof NO_ACCOUNT_CONNECTED
  payload: string
}

export const noAccountConnectedBanner = (): NoAccountConnectedI => ({
  type: NO_ACCOUNT_CONNECTED_BANNER,
  payload:
    'You are moments away from connecting to servers to get your analytics among 5000 other traders.',
})

export const noAccountConnected = (): NoAccountConnectedI => ({
  type: NO_ACCOUNT_CONNECTED,
  payload: '',
})

type GetAccId = boolean | string

export const mobileAndLocationConfirmed = (): NoAccountConnectedI => ({
  type: NO_ACCOUNT_CONNECTED,
  payload: '',
})

export const getAccountId = (state: StoreState): GetAccId => {
  const accno = state.user.account_number

  const current_account = state.user.logged_in.accounts.find(
    (account) => account.account_number === accno
  )

  if (!current_account) {
    Error('No trading account is selected.')
    return false
  }

  return current_account.account_id
}
