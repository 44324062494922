import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

interface IPortal {
  selector: string
  children: JSX.Element | JSX.Element[]
}

export default (props: IPortal) => {
  const [findElement, setFindElement] = useState<any>()
  const [element, setElement] = useState<Element>()

  useEffect(() => {
    setFindElement(setInterval(getElement, 20))
  }, [])

  useEffect(() => {
    clearInterval(findElement)
  }, [element])

  const getElement = () => {
    setElement(document.querySelector(props.selector))
  }

  if (element) {
    return ReactDOM.createPortal(props.children, element)
  }
  return null
}
