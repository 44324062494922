import produce, { Draft, enableES5 } from 'immer'
import { SET_LAST_READ_MESSAGE_TIMETOKEN } from './actionTypes'

import { LastReadMessageActions, InitialState } from './lastReadMessageTypes'

enableES5()

export const initial_state: InitialState = {
  timetoken: localStorage.getItem('latest-timetoken') || '',
}

export default produce((draft: Draft<InitialState>, action: LastReadMessageActions) => {
  switch (action.type) {
    case SET_LAST_READ_MESSAGE_TIMETOKEN:
      localStorage.setItem('latest-timetoken', action.payload)
      draft.timetoken = action.payload
      break
    default:
      break
  }
}, initial_state)
