import styles from './avatar.module.scss'
import React from 'react'
interface IAvatar {
  fullname?: string
  src?: string
  onClick?: () => void
}
const Avatar = ({ src, fullname = '', onClick }: IAvatar) => {
  if (!src && !fullname) {
    throw new Error('Avatar requires a source or name')
  }
  if (src) {
    return src ? (
      <img
        className={`${styles.avatar} ${onClick ? styles.avatarHover : ''}`}
        alt={fullname}
        src={src}
        onClick={onClick ? onClick : null}
      />
    ) : null
  }
  const initials = fullname.split(' ').map((n: string) => n[0])

  return (
    <div
      className={`${styles.avatar} ${styles.avatarGradient} ${
        onClick ? styles.avatarHover : ''
      }`}
      onClick={onClick ? onClick : null}
    >
      {initials}
    </div>
  )
}

export default Avatar
