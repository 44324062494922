import { setConfiguration } from 'react-grid-system'

export enum Breakpoints {
  xs = 768,
  sm = 992,
  md = 1360,
  lg = 1600,
  xl = 1920,
}

export default setConfiguration({
  maxScreenClass: 'xxl',
  containerWidths: [700, 920, 1320, 1540, 1760],
  breakpoints: [
    Breakpoints.xs,
    Breakpoints.sm,
    Breakpoints.md,
    Breakpoints.lg,
    Breakpoints.xl,
  ],
})
