const getBearerToken = () => {
  let cookie_value
  if (document.cookie.split(';').some((item) => item.trim().startsWith('tfxbearer='))) {
    cookie_value = document.cookie
      .split(';')
      .find((item) => item.trim().startsWith('tfxbearer='))
      .split('=')[1]
  }
  return cookie_value
}

export default getBearerToken
