import produce, { Draft, enableES5 } from 'immer'
import { SET_CUSTOM_DATA_PERIOD, SET_DATA_PERIOD, SET_SCREEN_BLUR, SET_ALL_DATA_LOADED} from './actionTypes'
import {
  ALL_HISTORY_PERIOD,
  CUSTOM_PERIOD,
  THIS_MONTH_PERIOD,
  THIS_WEEK_PERIOD,
} from '../../consts'
import { InitialState, MetricsPeriodActions } from './metricsPeriodTypes'
import moment from 'moment'
enableES5()

export const initial_state: InitialState = {
  date_from: moment().subtract(100, 'years'),
  date_to: null,
  period: ALL_HISTORY_PERIOD,
  blur_screen:0,
  all_data_loaded:0
}

export default produce((draft: Draft<InitialState>, action: MetricsPeriodActions) => {
  switch (action.type) {
    case SET_DATA_PERIOD:
      if (action.payload === ALL_HISTORY_PERIOD) {
        draft.date_from = moment().subtract(100, 'years')
        draft.date_to = null
        draft.period = action.payload
      } else if (action.payload === THIS_WEEK_PERIOD) {
        draft.date_from = moment().day(0)
        draft.date_to = null
        draft.period = action.payload
      } else if (action.payload === THIS_MONTH_PERIOD) {
        draft.date_from = moment().month(moment().month() - 1)
        draft.date_to = null
        draft.period = action.payload
      } else if (action.payload === CUSTOM_PERIOD) {
        draft.period = action.payload
      }
      window.localStorage.setItem('lastTab', action.payload)
      break

    case SET_CUSTOM_DATA_PERIOD:
      draft.date_to = moment(action.payload.date_to)
      draft.date_from = moment(action.payload.date_from)
      break

    case SET_SCREEN_BLUR:
      draft.blur_screen= action.payload
      break

    case SET_ALL_DATA_LOADED:
      draft.all_data_loaded= action.payload
      break

    default:
      break
  }
}, initial_state)
