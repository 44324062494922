import React from 'react'
import Modal from 'react-modal'
import Portal from '../portal'

import colors from '../../scss/_colors.scss'

interface IModal {
  open: boolean
  children: JSX.Element
  onClose?: () => void
  collapsed?: boolean
  admin?: boolean
  width?: number
  height?: number
  overflow?: string
}

const ModalWrapper = ({
  open,
  onClose,
  children,
  collapsed,
  admin,
  width,
  height,
  overflow,
}: IModal): JSX.Element | null => {
  const customStyles = {
    content: {
      position: 'relative',
      width: `${width || 300}px`,
      height: height ? `${height}px` : collapsed ? 'auto' : '476px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '32px',
      transform: 'translate(-50%, -50%)',
      zIndex: 100,
      transition: 'height .25s ease',
      overflow: overflow || 'hidden',
      boxSizing: 'border-box',
      border: '1px solid var(--principal-dark-blue, #3953A7)',
      borderRadius: 10,
      background: admin ? colors.white : 'var(--neutral-600, #11141A)',
      boxShadow: 'rgba(102, 98, 96, 0.25) 0px 48px 100px 0px',
    },
    overlay: {
      backgroundColor: colors.darkerGreyAlpha,
      zIndex: 99999,
    },
  }

  return (
    <Portal selector="#modal">
      <Modal
        isOpen={open}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick
        style={customStyles}
        contentLabel="Modal"
        ariaHideApp={false}
      >
        {children}
      </Modal>
    </Portal>
  )
}

export default ModalWrapper
