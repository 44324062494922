import { SET_DATA_PERIOD, SET_CUSTOM_DATA_PERIOD, SET_SCREEN_BLUR, SET_ALL_DATA_LOADED } from './actionTypes'
import { SetCustomDataPeriod, SetDataPeriod, SetScreenBlurr, SetAllDataLoaded } from './metricsPeriodTypes'

export const setDataPeriod = (data_period: string): SetDataPeriod => {
  return {
    type: SET_DATA_PERIOD,
    payload: data_period,
  }
}

export const setCustomDataPeriod = (
  date_from: Date,
  date_to: Date
): SetCustomDataPeriod => {
  return {
    type: SET_CUSTOM_DATA_PERIOD,
    payload: { date_from, date_to },
  }
}


export const setScreenBlurr = (flag:number):SetScreenBlurr=>{
return{
  type: SET_SCREEN_BLUR,
  payload:flag

}
  
}


export const setAllDataLoaded = (flag:number):SetAllDataLoaded=>{
  return{
    type: SET_ALL_DATA_LOADED,
    payload:flag
  
  }
    
  }