import produce, { Draft, enableES5 } from 'immer'
import {
  STORE_ALL_USERS,
  STORE_STAGES,
  STORE_STAFF,
  STORE_FILTERED_USERS,
  SET_FILTER_STAGE,
  SET_FILTER_STAFF_IDS,
  STORE_UNASSIGNED_USERS,
  STORE_AVAILABLE_COUNTRIES,
  STORE_FILTERED_COUNTRY,
  STORE_USER_IDS_FOR_COUNTRY,
  CLEAR_COUNTRY_FILTER,
  STORE_UNASSIGNED_ID_SELECTION,
  STORE_ACCESS_LEVELS,
  SET_ACTIVE_STAGE_FILTER_MEMBERS,
  SET_ACTIVE_STAGE_FILTER_ACCOUNTS,
  ADMIN_GET_SALE,
  SALES_GET_SALE,
  ADMIN_TIME_TOKEN,
  INDIVIDUAL_MESSAGE_COUNT, 
  STORE_ADMIN_STATS,
  SET_FILTER_SORT_COL,
  SET_FILTER_SORT_VALUE,
} from './actionTypes'
import { InitialState, UserActions } from './adminTypes'

enableES5()

export const initial_state: InitialState = {
  assigned_users: [],
  staff: [],
  stages: [],
  unassigned_users: [],
  user_countries: [],
  selected_country: {},
  selected_country_user_ids: [],
  selected_unassigned_users: [],
  saleList: [],
  confirmSaleList: [],
  access_levels: [],
  filter: {
    active_filtered_stages_members: [],
    active_filtered_stages_accounts: [],
  },
  loading: {
    unassigned_users: true,
    assigned_users: true,
    filtered_users:true,
    staff: true,
  },
  admin_time_token:'',
  individual_message_count:{},
  unassigned_users_pagination:{},
  assigned_users_pagination:{},
  admin_stats:{},  
  filtered_users:[],
  filtered_users_pagination:{},
  filtered_users_filter:{
    stage:"all",
    staff_ids:"",
    sort_col:"date_joined",
    sort_value:'desc'
  }
  
}

export default produce((draft: Draft<InitialState>, action: UserActions) => {
  switch (action.type) {
    case ADMIN_TIME_TOKEN:
      draft.admin_time_token = action.payload
      break
  
    case INDIVIDUAL_MESSAGE_COUNT:
      draft.individual_message_count = action.payload
      break
      case SET_FILTER_STAGE:
        draft.filtered_users_filter.stage = action.payload ? action.payload : "all"
        break

        case SET_FILTER_STAFF_IDS:
          draft.filtered_users_filter.staff_ids = action.payload ? action.payload : ""
          break 

          case SET_FILTER_SORT_COL:
            draft.filtered_users_filter.sort_col = action.payload ? action.payload : ""
            break   

            case SET_FILTER_SORT_VALUE:
              draft.filtered_users_filter.sort_value = action.payload ? action.payload : ""
              break   

    case STORE_ALL_USERS:
      draft.assigned_users = action.payload.users
      draft.assigned_users_pagination = action.payload.pagination
      draft.loading.assigned_users = false
      break

    case STORE_ADMIN_STATS:
      draft.admin_stats = action.payload
      break

    case STORE_STAFF:
      draft.staff = action.payload.users
      draft.loading.staff = false
      break

    case STORE_UNASSIGNED_USERS:
      draft.unassigned_users = action.payload.users
      draft.unassigned_users_pagination = action.payload.pagination
      draft.loading.unassigned_users = false
      break

      case STORE_FILTERED_USERS:
        draft.filtered_users = action.payload.accounts
        draft.filtered_users_pagination = action.payload.pagination
        draft.loading.filtered_users = false
        break

    case STORE_STAGES:
      draft.stages = action.payload
      break

    case STORE_AVAILABLE_COUNTRIES:
      draft.user_countries = action.payload
      break

    case STORE_FILTERED_COUNTRY:
      draft.selected_country = action.payload
      break

    case STORE_ACCESS_LEVELS:
      draft.access_levels = action.payload
      break

    case STORE_USER_IDS_FOR_COUNTRY:
      draft.selected_country_user_ids = action.payload
      break

    case CLEAR_COUNTRY_FILTER:
      draft.selected_country = {}
      draft.selected_country_user_ids = []
      break

    case STORE_UNASSIGNED_ID_SELECTION:
      // draft.selected_unassigned_users = (() => {
      //   return draft.unassigned_users.filter((user) => {
      //     if (action.payload.includes(user.id)) return true
      //     return false
      //   })
      // })()
      draft.selected_unassigned_users = action.payload

      break

    case SET_ACTIVE_STAGE_FILTER_MEMBERS:
      draft.filter.active_filtered_stages_members = action.payload
      break

    case SET_ACTIVE_STAGE_FILTER_ACCOUNTS:
      draft.filter.active_filtered_stages_accounts = action.payload
      break
    case ADMIN_GET_SALE:
      draft.saleList = action.payload
      break
    case SALES_GET_SALE:
      draft.confirmSaleList = action.payload
      break
    default:
      break
  }
}, initial_state)
