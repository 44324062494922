import produce, { Draft, enableES5 } from 'immer'
import {
  STORE_FEED
} from './actionTypes'
import { InitialState, AuthAction } from './superAdminTypes'

enableES5()

export const initial_state: InitialState = {
  feed: []
}

export default produce((draft: Draft<InitialState>, action: AuthAction) => {
  switch (action.type) {
    
    case STORE_FEED:
      draft.feed = action.payload
      break
  }
}, initial_state)
