import React from 'react'
import { Link } from 'react-router-dom'
import styles from './link.module.scss'

interface LinkWrapper {
  label: string
  path?: string
  notificationCount?: number
  active?: boolean
  className?: string
  sidebar?: boolean
  onClick?: (menu:number)=>void
  disabled?: boolean
  darkTheme?: boolean
  target?: string
  user?:string
  menuOption?:number
  adminHeader:boolean
}

const LinkWrapper = ({
  label,
  path = '',
  notificationCount,
  active,
  sidebar,
  onClick=null,
  disabled,
  darkTheme,
  target = '',
  className = '',
  menuOption=0,
  adminHeader=false
}: LinkWrapper) => {
  const showCountLabel = typeof notificationCount === 'number' && notificationCount > 0

  if (adminHeader){
    return(
      
      <span
    className={`
      ${styles.link}
      ${sidebar ? styles.sidebarLink : ''}
      ${disabled ? styles.linkDisabled : ''}
      ${className}
    `}
    
  >
    <span
      className={`
        ${styles.linkLabel} 
        ${active ? styles.active : ''}
        ${darkTheme ? styles.linkLabelDarkTheme : ''}
        ${disabled && (menuOption===0) ?  styles.linkLabelDisabled: ''}
      `}
      onClick={!disabled ? ()=>{} :  ()=>onClick(menuOption) }
    >
      {label}
    </span>
    {showCountLabel && (
      <span className={styles.linkNotification}>
        {notificationCount > 9 ? '9+' : notificationCount}
      </span>
    )}
  </span>
      
    )
  }
  return (
    <>
    {disabled
    ?
    <Link
    className={`
      ${styles.link}
      ${sidebar ? styles.sidebarLink : ''}
      ${disabled ? styles.linkDisabled : ''}
      ${className}
    `}
    
  >
    <span
      className={`
        ${styles.linkLabel} 
        ${active ? styles.active : ''}
        ${darkTheme ? styles.linkLabelDarkTheme : ''}
        ${disabled && (menuOption===0) ?  styles.linkLabelDisabled: ''}
      `}
      onClick={!disabled ? ()=>{} :  ()=>onClick(menuOption) }
    >
      {label}
    </span>
    {showCountLabel && (
      <span className={styles.linkNotification}>
        {notificationCount > 9 ? '9+' : notificationCount}
      </span>
    )}
  </Link>:
    <Link
      className={`
        ${styles.link}
        ${sidebar ? styles.sidebarLink : ''}
        ${disabled ? styles.linkDisabled : ''}
        ${className}
      `}
      to={path}
      target={target}
    >
      <span
        className={`
          ${styles.linkLabel} 
          ${active ? styles.active : ''}
          ${darkTheme ? styles.linkLabelDarkTheme : ''}
          ${disabled ? styles.linkLabelDisabled : ''}
        `}
        onClick={!disabled ? ()=>onClick(menuOption) : () => {}}
      >
        {label}
      </span>
      {showCountLabel && (
        <span className={styles.linkNotification}>
          {notificationCount > 9 ? '9+' : notificationCount}
        </span>
      )}
    </Link>}</>
  )
}

export default LinkWrapper
