import produce, { Draft, enableES5 } from 'immer'
import {
  FAILED_TO_ADD_ACCOUNT,
  SUCCESSFULLY_ADDED_ACCOUNT,
  NO_ACCOUNT_CONNECTED,
  SUCCESSFULLY_REMOVED_ACCOUNT,
  NO_ACCOUNT_CONNECTED_BANNER,
  CLEAR_ACCOUNT_CHANGE_MESSAGE,
} from '../user/actionTypes'

import { InitialState, AddAccountAction } from './accountsChangeMessageTypes'

enableES5()

export const initial_state: InitialState = {
  message: null,
  banner_message: null,
  show_no_account_banner: false,
}

export default produce((draft: Draft<InitialState>, action: AddAccountAction) => {
  switch (action.type) {
    case FAILED_TO_ADD_ACCOUNT:
      draft.message = action.payload
      break
    case SUCCESSFULLY_ADDED_ACCOUNT:
      draft.message = action.payload
      draft.show_no_account_banner = false
      break

    case SUCCESSFULLY_REMOVED_ACCOUNT:
      draft.message = action.payload
      break

    case NO_ACCOUNT_CONNECTED:
      draft.message = action.payload
      draft.show_no_account_banner = true
      break

    case NO_ACCOUNT_CONNECTED_BANNER:
      draft.banner_message = action.payload
      draft.show_no_account_banner = true
      break

    case CLEAR_ACCOUNT_CHANGE_MESSAGE:
      draft.message = null
      draft.banner_message = null
      draft.show_no_account_banner = false
      break

    default:
      break
  }
}, initial_state)
