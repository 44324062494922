export const LANDING_PATH = '/'
export const LOGIN_PATH = '/login'
export const PUBLIC_BROKER_PATH = '/broker'
export const SIGNUP_PATH = '/signupp'
export const METRICS_PATH = '/members/metrics'
export const TRADES_PATH = '/members/trades'
export const DEPOSIT_WITHDRAW_PATH = '/members/deposit-withdrawal'
export const BROKER_PATH = '/members/broker'
export const TRACK_PATH = '/members/track'
export const PROFILE_PATH = '/members/profile'
export const SUBSCRIPTION_PATH = '/members/subscription'
export const MANAGE_ACCOUNTS_PATH = '/members/manage-accounts'
export const ADD_ACCOUNT_PATH = '/members/add-account'
export const MANAGE_SUBSCRIPTION_PATH = '/members/manage-subscription'
export const MANAGE_PAYMENT_INFO_PATH = '/members/manage-payment-info-path'
export const ADMIN_FEED_PATH = '/admin/feed'
export const ACCOUNTS_SETUP_PATH = '/members/accounts-setup'
export const ADMIN_ROOT_PATH = '/admin'
export const ADMIN_DASHBOARD_PATH = '/admin'
export const ADMIN_STAFF_PATH = '/admin/staff'
export const ADMIN_ASSIGNED_ACCOUNTS_PATH = '/admin/assigned'
export const ADMIN_MEMBERS_PATH = '/admin/members'
export const ADMIN_CHAT_PATH = '/admin/chat'
export const ADMIN_CHAT_PATH_USER = '/admin/chat/:id'
export const ADMIN_CALENDAR_PATH = '/admin/calendar'
export const ADMIN_PROFILE_PATH = '/admin/profile'
export const ADMIN_SALES_PATH = '/admin/sales'
export const ADMIN_METRICS_PATH = '/admin/metrics'
export const ADMIN_STATS_PATH = '/admin/stats'
export const ADMIN_TRADERS_PATH = '/admin/traders'
export const ADMIN_STRATEGY_PATH = '/admin/strategy'
export const ADMIN_TRADER_METRICS_PATH = '/admin/traderMetrics'

export const PRIVACY_POLICY_PATH = '/privacypolicy'
export const TERMS_AND_CONDITIONS_PATH = '/terms'
export const TERMS_AND_CONDITIONS_SIGNUP_PATH = '/terms_and_conditions'
export const COOKIES_PATH = '/cookies'
export const RESET_PASSWORD = '/login/reset_password'
export const INVITE_REGISTRATION = '/invite_registration'
export const PAYMENT_REDIRECTION_PATH = '/3dreturn'

export const ASSET_PAIRS = [
  'ALL',
  'AUDCAD',
  'AUDCHF',
  'AUDJPY',
  'AUDNZD',
  'AUDUSD',
  'CADCHF',
  'CADJPY',
  'CHFJPY',
  'EURAUD',
  'EURCAD',
  'EURCHF',
  'EURGBP',
  'EURJPY',
  'EURUSD',
  'GBPAUD',
  'GBPCAD',
  'GBPCHF',
  'GBPJPY',
  'GBPNZD',
  'GBPUSD',
  'NZDCAD',
  'NZDCHF',
  'NZDJPY',
  'NZDUSD',
  'USDCAD',
  'USDCHF',
  'USDJPY',
  'EURNZD',
  'XAUUSD',
]
export const ASSET_PAIR_LABELS = {
  AUDCAD: 'AUD/CAD',
  AUDCHF: 'AUD/CHF',
  AUDJPY: 'AUD/JPY',
  AUDNZD: 'AUD/NZD',
  AUDUSD: 'AUD/USD',
  CADCHF: 'CAD/CHF',
  CADJPY: 'CAD/JPY',
  CHFJPY: 'CHF/JPY',
  EURAUD: 'EUR/AUD',
  EURCAD: 'EUR/CAD',
  EURCHF: 'EUR/CHF',
  EURGBP: 'EUR/GBP',
  EURJPY: 'EUR/JPY',
  EURUSD: 'EUR/USD',
  GBPAUD: 'GBP/AUD',
  GBPCAD: 'GBP/CAD',
  GBPCHF: 'GBP/CHF',
  GBPJPY: 'GBP/JPY',
  GBPNZD: 'GBP/NZD',
  GBPUSD: 'GBP/USD',
  NZDCAD: 'NZD/CAD',
  NZDCHF: 'NZD/CHF',
  NZDJPY: 'NZD/JPY',
  NZDUSD: 'NZD/USD',
  USDCAD: 'USD/CAD',
  USDCHF: 'USD/CHF',
  USDJPY: 'USD/JPY',
  EURNZD: 'EUR/NZD',
  XAUUSD: 'XAU/USD',
  USDXAU: 'USD/XAU',
  ALL: 'ALL',
}

export const ALL_HISTORY_PERIOD = 'all'
export const THIS_WEEK_PERIOD = 'weekly'
export const THIS_MONTH_PERIOD = 'monthly'
export const CUSTOM_PERIOD = 'custom'

export const TRACKFX_WELCOME_MESSAGE =
  "Welcome to SentryLabs. I'm your advisor. If you want advice or support with your trading, get in touch with me here."

export const COMMUNITY_FAQ_QUESTIONS: string[] = [
  'I need some help understanding a particular statistic more.',
  'Which statistics should I be focusing on considering my performance?',
  'How can I improve my current metrics?',
  'I have a specific issue, can I speak with you over the phone?',
  'I have a suggestion for this platform',
]
