import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import store from './redux/store'

//import 'inset.js'
import { install } from 'resize-observer'

import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const LandingPage = React.lazy(() => import('./pages/landing'))
const LoginPage = React.lazy(() => import('./pages/login'))
const SignupPage = React.lazy(() => import('./pages/signup'))
const PrivacyPolicyPage = React.lazy(() => import('./pages/privacypolicy'))
const TermsAndConditionsPage = React.lazy(() => import('./pages/termsAndConditions'))
const TermsAndConditionsSignupPage = React.lazy(
  () => import('./pages/termsAndConditionsSignup')
)
const CookiesPage = React.lazy(() => import('./pages/cookies'))
const MetricsPage = React.lazy(() => import('./pages/metrics'))
const TradesPage = React.lazy(() => import('./pages/trades'))
const DepositWithdrawPage = React.lazy(() => import('./pages/depositWithdraw'))
const Broker = React.lazy(() => import('./pages/broker'))
const Track = React.lazy(() => import('./pages/track'))
const ProfilePage = React.lazy(() => import('./pages/profile'))
const ManageAccountsPage = React.lazy(() => import('./pages/manageAccounts'))
const AddAccountPage = React.lazy(() => import('./pages/addAccount'))
const ManageSubscriptionPage = React.lazy(() => import('./pages/manageSubscription'))
const AccountsSetupPage = React.lazy(() => import('./pages/accountsSetup'))
const AdminPage = React.lazy(() => import('./pages/admin'))
const PaymentRedirection = React.lazy(() => import('./pages/paymentRedirection'))
const ManagePaymentInfo = React.lazy(() => import('./pages/managePaymentInfo'))

import Auth from './components/auth'
import Header from './components/header'
import Footer from './components/footer'

import * as CONSTS from './consts'
import styles from './app.module.scss'
import Loader from './components/loader'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ScreenClassProvider } from 'react-grid-system'
import FallbackLoader from './components/fallbackLoader'

const App = () => {
  install()
  const shouldShow = (path) => {
    return (
      path === CONSTS.METRICS_PATH ||
      path === CONSTS.TRADES_PATH ||
      path === CONSTS.DEPOSIT_WITHDRAW_PATH ||
      path === CONSTS.BROKER_PATH ||
      path === CONSTS.TRACK_PATH ||
      path === CONSTS.PROFILE_PATH ||
      path === CONSTS.SUBSCRIPTION_PATH ||
      path === CONSTS.MANAGE_ACCOUNTS_PATH ||
      path === CONSTS.ADD_ACCOUNT_PATH ||
      path === CONSTS.MANAGE_SUBSCRIPTION_PATH ||
      path === CONSTS.MANAGE_PAYMENT_INFO_PATH ||
      path === CONSTS.ACCOUNTS_SETUP_PATH
    )
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ width: '100%' }}
        limit={1}
      />
      <ScreenClassProvider>
        <Provider store={store}>
          <Router>
            <Suspense fallback={<FallbackLoader />}>
              <Route path={CONSTS.LANDING_PATH} exact component={LoginPage} />
              <Route path={CONSTS.LOGIN_PATH} exact component={LoginPage} />
              <Route path={CONSTS.SIGNUP_PATH} exact component={SignupPage} />
              <Route path={CONSTS.RESET_PASSWORD} exact component={LoginPage} />
              <Route path={CONSTS.INVITE_REGISTRATION} exact component={LandingPage} />
              <Route
                path={CONSTS.PRIVACY_POLICY_PATH}
                exact
                component={PrivacyPolicyPage}
              />
              <Route
                path={CONSTS.TERMS_AND_CONDITIONS_PATH}
                exact
                component={TermsAndConditionsPage}
              />
              <Route
                path={CONSTS.TERMS_AND_CONDITIONS_SIGNUP_PATH}
                exact
                component={TermsAndConditionsSignupPage}
              />
              <Route path={CONSTS.COOKIES_PATH} exact component={CookiesPage} />
              <Route
                path={CONSTS.PAYMENT_REDIRECTION_PATH}
                component={PaymentRedirection}
              />
              <Route path={CONSTS.PUBLIC_BROKER_PATH} exact component={Broker} />
            </Suspense>
            <Auth>
              <>
                <Route
                  path="/"
                  render={(props) => shouldShow(props.location.pathname) && <Header />}
                ></Route>
                <div className={styles.mainPage}>
                  <div className={styles.mainPageBack}></div>
                  <div className={styles.mainPageContent}>
                    <Suspense fallback={<Loader />}>
                      <Route path={CONSTS.METRICS_PATH} exact component={MetricsPage} />
                      {/* <Route
                        path={CONSTS.ADMIN_METRICS_PATH}
                        exact
                        component={MetricsPage}
                      /> */}
                      <Route path={CONSTS.TRADES_PATH} exact component={TradesPage} />
                      <Route
                        path={CONSTS.DEPOSIT_WITHDRAW_PATH}
                        exact
                        component={DepositWithdrawPage}
                      />
                      <Route path={CONSTS.BROKER_PATH} exact component={Broker} />
                      <Route path={CONSTS.TRACK_PATH} exact component={Track} />
                      <Route path={CONSTS.PROFILE_PATH} exact component={ProfilePage} />
                      {/* <Route path={CONSTS.SUBSCRIPTION_PATH} exact component={SubscriptionPage} />  */}
                      <Route
                        path={CONSTS.MANAGE_ACCOUNTS_PATH}
                        exact
                        component={ManageAccountsPage}
                      />
                      <Route
                        path={CONSTS.ADD_ACCOUNT_PATH}
                        exact
                        component={AddAccountPage}
                      />
                      <Route
                        path={CONSTS.MANAGE_SUBSCRIPTION_PATH}
                        exact
                        component={ManageSubscriptionPage}
                      />
                      <Route
                        path={CONSTS.ACCOUNTS_SETUP_PATH}
                        exact
                        component={AccountsSetupPage}
                      />
                      {/* <Route path={CONSTS.FEED_PATH} exact component={FeedPage}/> */}
                      <Route path={CONSTS.ADMIN_ROOT_PATH} component={AdminPage} />
                      <Route
                        path={CONSTS.MANAGE_PAYMENT_INFO_PATH}
                        component={ManagePaymentInfo}
                      />
                    </Suspense>
                  </div>
                </div>
                <Route
                  path="/"
                  render={(props) => shouldShow(props.location.pathname) && <Footer />}
                ></Route>
              </>
            </Auth>
          </Router>
          <div id="modal"></div>
        </Provider>
      </ScreenClassProvider>
    </>
  )
}

export default App
