export const STORE_ALL_USERS = 'admin/store-assigned-users'
export const STORE_STAGES = 'admin/store-stages'
export const STORE_STAFF = 'admin/store-staff'
export const STORE_UNASSIGNED_USERS = 'admin/store-unassigned-users'
export const STORE_FILTERED_USERS = 'admin/store-filtered-users'
export const STORE_AVAILABLE_COUNTRIES = 'admin/store-available-countries'
export const STORE_FILTERED_COUNTRY = 'admin/store-filtered-country-code'
export const STORE_USER_IDS_FOR_COUNTRY = 'admin/store-user-ids-for-country'
export const CLEAR_COUNTRY_FILTER = 'admin/clear-country-filter'
export const STORE_UNASSIGNED_ID_SELECTION = 'admin/store-unassigned-id-selection'
export const STORE_ACCESS_LEVELS = 'admin/store-access-levels'
export const SET_ACTIVE_STAGE_FILTER_MEMBERS = 'admin/set-active-stage-filter-members'
export const SET_ACTIVE_STAGE_FILTER_ACCOUNTS = 'admin/set-active-stage-filter-accounts'
export const ADMIN_GET_SALE = 'admin/getsale'
export const SALES_GET_SALE = 'admin/getconfirmedSale'
export const ADMIN_TIME_TOKEN = 'admin/time-token'
export const INDIVIDUAL_MESSAGE_COUNT = 'admin/individual-message-count'
export const STORE_ADMIN_STATS = 'admin/stats'
export const SET_FILTER_STAFF_IDS = 'admin/set-filter-staff-ids'
export const SET_FILTER_STAGE = 'admin/set-filter-stage'
export const SET_FILTER_SORT_COL = 'admin/set-filter-sort-col'
export const SET_FILTER_SORT_VALUE = 'admin/set-filter-sort-value'
