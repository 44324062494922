import produce, { Draft, enableES5 } from 'immer'
import { GET_NEWS, GET_TRADE_SENTIMENT } from './actionTypes'
import { TrackActions, InitialState } from './trackTypes'

enableES5()

export const initial_state: InitialState = {
  tradeSentiment: null,
  news: null,
}

export default produce((draft: Draft<InitialState>, action: TrackActions) => {
  switch (action.type) {
    case GET_TRADE_SENTIMENT:
      draft.tradeSentiment = action.payload
      break
    case GET_NEWS:
      draft.news = action.payload
      break
  }
}, initial_state)
