import Loader from '../loader'
import styles from './styles.module.scss'

const FallbackLoader = () => {
  return (
    <div className={styles.fallbackWrapper}>
      <Loader />
    </div>
  )
}

export default FallbackLoader
