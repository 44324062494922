import { Dispatch, Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { noAccountConnected, getAccountId } from '../globalActions'
import { Store as StoreState } from '../store'
import { Moment } from 'moment'
import api from '../../api'
import {
  STORE_CASH_BY_PERIOD,
  STORE_PIP_BY_PERIOD,
  STORE_STATS,
  STORE_ASSET_STATS,
  NO_STATS_AVAILABLE,
  STORE_TOP_TRADING_DAYS,
  STORE_AGG_CASH_BY_PERIOD,
  STORE_AGG_PIP_BY_PERIOD,
  STORE_GROWTH_BY_PERIOD,
  STORE_AGG_GROWTH_BY_PERIOD,
  STORE_GROWTH_BY_PERIOD_GROUPED,
  STORE_CASH_BY_PERIOD_ALL,
  STORE_PIP_BY_PERIOD_ALL,
  STORE_STATS_ALL,
  STORE_ASSET_STATS_ALL,
  STORE_TOP_TRADING_DAYS_ALL,
  STORE_AGG_CASH_BY_PERIOD_ALL,
  STORE_AGG_PIP_BY_PERIOD_ALL,
  STORE_GROWTH_BY_PERIOD_ALL,
  STORE_AGG_GROWTH_BY_PERIOD_ALL,
  STORE_GROWTH_BY_PERIOD_GROUPED_ALL,
} from './actionTypes'
import { THIS_WEEK_PERIOD, ALL_HISTORY_PERIOD, THIS_MONTH_PERIOD } from '../../consts'
import {
  AssetCombinedByPeriod,
  CashCombinedByPeriod,
  GrowthCombinedByPeriod,
  GrowthByPeriodGrouped,
  GetTradingDays,
  StoreStats,
  StoreAssetStats,
} from './metricsTypes'
import { fetchError } from '../error/actions'
import { setScreenBlurr, setAllDataLoaded } from '../metricsPeriod/actions'
const API = api()

interface PeriodCountResponse {
  period: 'daily' | 'weekly' | 'yearly' | 'monthly' | 'quarterly'
  group: 'daily' | 'weekly' | 'yearly' | 'monthly' | 'quarterly' | 'asset'
  count: number
}

const periodAndCount = (date_period: string): PeriodCountResponse => {
  if (date_period === THIS_WEEK_PERIOD) {
    return {
      period: 'weekly',
      group: 'daily',
      count: 1,
    }
  } else if (date_period === THIS_MONTH_PERIOD) {
    return {
      period: 'monthly',
      group: 'daily',
      count: 1,
    }
  } else if (date_period === ALL_HISTORY_PERIOD) {
    return {
      period: 'yearly',
      group: 'daily',
      count: 15,
    }
  }
}

const periodAndCountGrouped = (date_period: string): PeriodCountResponse => {

  if (date_period === THIS_WEEK_PERIOD) {
    return {
      period: 'weekly',
      group: 'daily',
      count: 1,
    }
  } else if (date_period === THIS_MONTH_PERIOD) {
    return {
      period: 'monthly',
      group: 'weekly',
      count: 1,
    }
  } else if (date_period === ALL_HISTORY_PERIOD) {
    return {
      period: 'yearly',
      group: 'monthly',
      count: 15,
    }
  }
}

const periodAndCountForAssests = (date_period: string): PeriodCountResponse => {
  if (date_period === THIS_WEEK_PERIOD) {
    return {
      period: 'weekly',
      group: 'asset',
      count: 1,
    }
  } else if (date_period === THIS_MONTH_PERIOD) {
    return {
      period: 'monthly',
      group: 'asset',
      count: 1,
    }
  } else if (date_period === ALL_HISTORY_PERIOD) {
    return {
      period: 'yearly',
      group: 'asset',
      count: 15,
    }
  }
}
export const getCashByPeriod = (
  date_period?: string | null,
  custom_dates?: Record<string, Moment>,
  agg?: 'total' | 'delta',
  access_level?: number | null,
): ThunkAction<void, StoreState, unknown, Action<CashCombinedByPeriod>> => async (
  dispatch: Dispatch,
  getState: () => StoreState
) => {
    const accno = getAccountId(getState())
    localStorage.setItem('track_fx_selected_account_id', accno)
    if (!accno) {
      dispatch(noAccountConnected())
      return
    }

    try {
      let charts
      let url
      if (date_period) {
        const { period, group, count } = periodAndCount(date_period)
        url = `/trader/${accno}/chart?period=${period}&group=${group}&count=${count}`
        if (access_level >= 50) {
          url += `&user_id=${localStorage.getItem('selected_staff_user')}`
        }
        charts = await API.charts({ url })
      } else {
        const { date_from, date_to } = custom_dates
        url = `/trader/${accno}/chart?date_from=${date_from.format(
          'YYYY-MM-DD'
        )}&date_to=${date_to.format('YYYY-MM-DD')}`
        if (access_level >= 50) {
          url += `&user_id=${localStorage.getItem('selected_staff_user')}`
        }
        charts = await API.charts({ url })
        dispatch({ type: STORE_CASH_BY_PERIOD, payload: charts.data })
        dispatch({ type: STORE_AGG_CASH_BY_PERIOD, payload: charts.data })
        // assetbyperios
        dispatch({ type: STORE_PIP_BY_PERIOD, payload: charts.data })
        dispatch({ type: STORE_AGG_PIP_BY_PERIOD, payload: charts.data })
        // getGrouthbyperiod
        dispatch({ type: STORE_GROWTH_BY_PERIOD, payload: charts.data })
        dispatch({ type: STORE_AGG_GROWTH_BY_PERIOD, payload: charts.data })
      }

      if (date_period === 'all') {
        dispatch({ type: STORE_CASH_BY_PERIOD_ALL, payload: charts.data })
        dispatch({ type: STORE_AGG_CASH_BY_PERIOD_ALL, payload: charts.data })
        // assetbyperios
        dispatch({ type: STORE_PIP_BY_PERIOD_ALL, payload: charts.data })
        dispatch({ type: STORE_AGG_PIP_BY_PERIOD_ALL, payload: charts.data })
        // getGrouthbyperiod
        dispatch({ type: STORE_GROWTH_BY_PERIOD_ALL, payload: charts.data })
        dispatch({ type: STORE_AGG_GROWTH_BY_PERIOD_ALL, payload: charts.data })
      } else if (date_period === 'monthly') {
        dispatch({ type: STORE_CASH_BY_PERIOD, payload: charts.data })
        dispatch({ type: STORE_AGG_CASH_BY_PERIOD, payload: charts.data })
        // assetbyperios
        dispatch({ type: STORE_PIP_BY_PERIOD, payload: charts.data })
        dispatch({ type: STORE_AGG_PIP_BY_PERIOD, payload: charts.data })
        // getGrouthbyperiod
        dispatch({ type: STORE_GROWTH_BY_PERIOD, payload: charts.data })
        dispatch({ type: STORE_AGG_GROWTH_BY_PERIOD, payload: charts.data })
      } else if (date_period === 'weekly') {

        dispatch({ type: STORE_CASH_BY_PERIOD, payload: charts.data })
        dispatch({ type: STORE_AGG_CASH_BY_PERIOD, payload: charts.data })
        // assetbyperios
        dispatch({ type: STORE_PIP_BY_PERIOD, payload: charts.data })
        dispatch({ type: STORE_AGG_PIP_BY_PERIOD, payload: charts.data })
        // getGrouthbyperiod
        dispatch({ type: STORE_GROWTH_BY_PERIOD, payload: charts.data })
        dispatch({ type: STORE_AGG_GROWTH_BY_PERIOD, payload: charts.data })
      }
    } catch (err) {
      dispatch(fetchError(err))
    }
  }

export const getAssetsByPeriod = (
  date_period: string | null,
  custom_dates: Record<string, Moment>,
): ThunkAction<void, StoreState, unknown, Action<AssetCombinedByPeriod>> => async (
  dispatch: Dispatch,
  getState: () => StoreState
) => {
    const accno = getAccountId(getState())

    if (!accno) {
      dispatch(noAccountConnected())
      return
    }

    try {
      let url
      if (date_period) {
        const { period, group, count } = periodAndCount(date_period)
        url = `/trader/${accno}/chart?period=${period}&group=${group}&count=${count}`
      } else {
        const { date_from, date_to } = custom_dates
        url = `/trader/${accno}/chart?date_from=${date_from.format(
          'YYYY-MM-DD'
        )}&date_to=${date_to.format('YYYY-MM-DD')}`
      }
      const charts = await API.charts({ url })
      dispatch({ type: STORE_PIP_BY_PERIOD, payload: charts.data })
      dispatch({ type: STORE_AGG_PIP_BY_PERIOD, payload: charts.data })
    } catch (err) {
      dispatch(fetchError(err))
    }
  }

export const getGrowthByPeriod = (
  date_period: string | null,
  custom_dates?: Record<string, Moment>,
): ThunkAction<void, StoreState, unknown, Action<GrowthCombinedByPeriod>> => async (
  dispatch: Dispatch,
  getState: () => StoreState
) => {
    const accno = getAccountId(getState())

    if (!accno) {
      dispatch(noAccountConnected())
      return
    }

    try {
      let url
      if (date_period) {
        const { period, group, count } = periodAndCount(date_period)
        url = `/trader/${accno}/chart?period=${period}&group=${group}&count=${count}`
      } else {
        const { date_from, date_to } = custom_dates
        url = `/trader/${accno}/chart?date_from=${date_from.format(
          'YYYY-MM-DD'
        )}&date_to=${date_to.format('YYYY-MM-DD')}`
      }
      const charts = await API.charts({ url })
      dispatch({ type: STORE_GROWTH_BY_PERIOD, payload: charts.data })
      dispatch({ type: STORE_AGG_GROWTH_BY_PERIOD, payload: charts.data })
    } catch (err) {
      dispatch(fetchError(err))
    }
  }
export const getGrowthByPeriodGrouped = (
  date_period: string | null,
  custom_dates?: Record<string, Moment>,
  access_level?: number | null
): ThunkAction<void, StoreState, unknown, Action<GrowthByPeriodGrouped>> => async (
  dispatch: Dispatch,
  getState: () => StoreState
) => {
    const accno = getAccountId(getState())

    if (!accno) {
      dispatch(noAccountConnected())
      return
    }

    try {
      let charts
      let url
      if (date_period) {
        const { period, group, count } = periodAndCountGrouped(date_period)
        url = `/trader/${accno}/chart?period=${period}&group=${group}&count=${count}`
        if (access_level >= 50) {
          url += `&user_id=${localStorage.getItem('selected_staff_user')}`
        }
        charts = await API.charts({ url })
      } else {
        const { date_from, date_to } = custom_dates
        const difference = date_to._d.getTime() - date_from._d.getTime()
        const group =
          difference > 5184000000 ? 'monthly' : difference > 864000000 ? 'weekly' : 'daily'

        url = `/trader/${accno}/chart?date_from=${date_from.format(
          'YYYY-MM-DD'
        )}&date_to=${date_to.format('YYYY-MM-DD')}&group=${group}`
        if (access_level >= 50) {
          url += `&user_id=${localStorage.getItem('selected_staff_user')}`
        }
        charts = await API.charts({ url })
        // dispatch({ type: STORE_GROWTH_BY_PERIOD_GROUPED, payload: charts.data })
      }
      
      if (date_period === 'all') {
        dispatch({ type: STORE_GROWTH_BY_PERIOD_GROUPED_ALL, payload: charts.data })
      } else if (date_period === 'monthly') {
        dispatch({ type: STORE_GROWTH_BY_PERIOD_GROUPED, payload: charts.data })
      } else if (date_period === 'weekly') {
        dispatch({ type: STORE_GROWTH_BY_PERIOD_GROUPED, payload: charts.data })
      }
    } catch (err) {
      dispatch(fetchError(err))
    }
  }

export const getTradingDays = (
  date_period: string | null,
  custom_dates?: Record<string, Moment>,
  access_level?: number | null,
): ThunkAction<void, StoreState, unknown, Action<GetTradingDays>> => async (
  dispatch: Dispatch,
  getState: () => StoreState
) => {
    const accno = getAccountId(getState())
    if (!accno) {
      dispatch(noAccountConnected())
      return
    }

    try {
      let week_stats
      let url
      if (date_period) {
        const { period, count } = periodAndCount(date_period)
        url = `/trader/${accno}/chart?period=${period}&group=${'weekday'}&count=${count}`
        if (access_level >= 50) {
          url += `&user_id=${localStorage.getItem('selected_staff_user')}`
        }
        week_stats = await API.charts({ url })
      } else {
        const { date_from, date_to } = custom_dates
        url = `/trader/${accno}/chart?date_from=${date_from.format(
          'YYYY-MM-DD'
        )}&date_to=${date_to.format('YYYY-MM-DD')}&group=${'weekday'}`
        if (access_level >= 50) {
          url += `&user_id=${localStorage.getItem('selected_staff_user')}`
        }
        week_stats = await API.charts({ url })
        dispatch({ type: STORE_TOP_TRADING_DAYS, payload: week_stats.data.delta })
      }

      if (date_period === 'all') {
        dispatch({ type: STORE_TOP_TRADING_DAYS_ALL, payload: week_stats.data.delta })
      } else if (date_period === 'monthly') {
        dispatch({ type: STORE_TOP_TRADING_DAYS, payload: week_stats.data.delta })
      } else if (date_period === 'weekly') {
        dispatch({ type: STORE_TOP_TRADING_DAYS, payload: week_stats.data.delta })
      }
    } catch (err) {
      dispatch(fetchError(err))
    }
    localStorage.setItem('all_data_loaded', 'false')
    setTimeout(() => {
      dispatch(setAllDataLoaded(Math.random()))
    }, 2400)
    localStorage.setItem('staffLoaded', 'false')
    setTimeout(() => {
      dispatch(setScreenBlurr(Math.random()))
    }, 1200)
  }

export const getTraderStats = (
  date_period?: string | null,
  custom_dates?: Record<string, Moment>,
  access_level?: number | null
): ThunkAction<void, StoreState, unknown, Action<StoreStats>> => async (
  dispatch: Dispatch,
  getState: () => StoreState
) => {
    const accno = getAccountId(getState())
    if (!accno) {
      dispatch(noAccountConnected())
      return
    }

    try {
      let stats
      if (date_period) {
        stats = await API.traderStats({
          accno,
          access_level,
          date_period,
        })
      } else {
        stats = await API.traderStats({
          accno,
          access_level,
          ...custom_dates,
        })
        setTimeout(() => dispatch({ type: STORE_STATS, payload: stats.data }), 1000)
      }
      if (date_period === 'all') {
        dispatch({ type: STORE_STATS_ALL, payload: stats.data })
      } else if (date_period === 'monthly') {
        dispatch({ type: STORE_STATS, payload: stats.data })
      } else if (date_period === 'weekly') {
        dispatch({ type: STORE_STATS, payload: stats.data })
      }
    } catch (err) {
      if (date_period === 'all') {
        dispatch({ type: NO_STATS_AVAILABLE, payload_all: 1 })
      } else if (date_period === 'monthly') {
        dispatch({ type: NO_STATS_AVAILABLE, payload_monthly: 1 })
      } else if (date_period === 'weekly') {
        dispatch({ type: NO_STATS_AVAILABLE, payload_weekly: 1 })
      } else if (date_period === null) {
        dispatch({ type: NO_STATS_AVAILABLE, payload: 1 })
      }
      dispatch(fetchError(err))
    }
  }

export const getAssetStats = (
  date_period: string | null,
  custom_dates?: Record<string, Moment>,
  access_level?: number | null
): ThunkAction<void, StoreState, unknown, Action<StoreAssetStats>> => async (
  dispatch: Dispatch,
  getState: () => StoreState
) => {
    const acc_id = getAccountId(getState())

    if (!acc_id) {
      dispatch(noAccountConnected())
      return
    }

    try {
      let week_stats
      if (date_period) {
        week_stats = await API.topAssetPairs({
          acc_id,
          access_level: access_level,
          agg: 'delta',
          ...periodAndCountForAssests(date_period),
        })
      } else {
        week_stats = await API.topAssetPairs({
          acc_id,
          access_level: access_level,
          // period: 'yearly',
          count: 1,
          group: 'asset',
          agg: 'delta',
          ...custom_dates,
        })
        dispatch({ type: STORE_ASSET_STATS, payload: week_stats.data.delta })
      }
      if (date_period === 'all') {
        dispatch({ type: STORE_ASSET_STATS_ALL, payload: week_stats.data.delta })
      } else if (date_period === 'monthly') {
        dispatch({ type: STORE_ASSET_STATS, payload: week_stats.data.delta })
      } else if (date_period === 'weekly') {
        dispatch({ type: STORE_ASSET_STATS, payload: week_stats.data.delta })
      }
    } catch (err) {
      dispatch(fetchError(err))
    }
  }
export const temp = (): ThunkAction<
  void,
  StoreState,
  unknown,
  Action<CashCombinedByPeriod>
> => async (dispatch: Dispatch, getState: () => StoreState) => {

}
