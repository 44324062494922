export const STORE_USER = 'user/store-user'
export const STORE_TRADES = 'user/store-trades'
export const STORE_TRADES_PAGINATION = 'user/store-trades-pagination'
export const STORE_CASH_BY_PERIOD = 'user/store-cash-by-period'
export const STORE_PIP_BY_PERIOD = 'user/store-pips-by-period'
export const STORE_STATS = 'user/store-stats'
export const STORE_ASSET_STATS = 'user/store-asset-stats'
export const STORE_ACCOUNT_NUMBER = 'user/store-account-number'
export const NO_STATS_AVAILABLE = 'user/no-stats-available'
export const STORE_TOP_TRADING_DAYS = 'user/store-top-trading-days'
export const ALL_LOADED = 'ALL_LOADED'
export const STORE_AGG_CASH_BY_PERIOD = 'user/store-agg-cash-by-period'
export const STORE_AGG_PIP_BY_PERIOD = 'user/store-agg-pip-by-period'

export const SET_SELECTED_ACCOUNT = 'user/set-selected-account'

export const FAILED_TO_ADD_ACCOUNT = 'user/failed-to-add-account'
export const NO_ACCOUNT_CONNECTED = 'user/no-account-added'
export const SUCCESSFULLY_ADDED_ACCOUNT = 'user/successfully-added-account'
export const SUCCESSFULLY_REMOVED_ACCOUNT = 'user/successfully-removed-account'
export const NO_ACCOUNT_CONNECTED_BANNER = 'user/no-account-connected-banner'
export const CLEAR_ACCOUNT_CHANGE_MESSAGE = 'user/clear-account-change-message'

export const STORE_BROKERS = 'user/store-brokers'
export const STORE_BROKERS_SERVERS = 'user/store-brokers-servers'

export const HISTORY_IS_LOADED = 'user/history-is-loaded'
export const HISTORY_IS_NOT_LOADED = 'user/history-is-not-loaded'

export const OPEN_ADD_ACCOUNT = 'user/open-add-acount'
export const CLOSE_ADD_ACCOUNT = 'user/close-add-acount'
export const CLOSE_REREGISTER_ACCOUNT = 'user/close-reregister-acount'
export const OPEN_REREGISTER_ACCOUNT = 'user/open-reregister-acount'

export const STORE_CASH_BY_PERIOD_ALL = 'user/store-cash-by-period-all'
export const STORE_TOP_TRADING_DAYS_ALL = 'user/store-top-trading-days-all'
export const STORE_AGG_CASH_BY_PERIOD_ALL = 'user/store-agg-cash-by-period-all'
export const STORE_AGG_PIP_BY_PERIOD_ALL = 'user/store-agg-pip-by-period-all'
export const STORE_PIP_BY_PERIOD_ALL = 'user/store-pips-by-period-all'
export const STORE_STATS_ALL = 'user/store-stats-all'
export const STORE_ASSET_STATS_ALL = 'user/store-asset-stats-all'

export const POLLING_IN_PROGRESS = 'user/store-polling-in-progress'
