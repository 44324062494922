// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1iJLzGLdps1rsXtL43CVHy{margin-top:25px}._3k4oCuqFDgmG4KFOHkxDg3{width:100%;padding:10px;border:none;margin-top:10px;background:rgba(0,0,0,0);border-radius:5px;color:#fff;font-family:\"soleil-book\";font-size:14px;outline:none;background:rgba(255,255,255,.1)}.jzoBQVzIT-EA6MRH1Wfir{background:#242831;color:#fff !important}", ""]);
// Exports
exports.locals = {
	"alphaGreen": "rgba(2,198,134,.28)",
	"alphaRed": "rgba(255,73,73,.28)",
	"aqua": "#00afd0",
	"aquaLight": "#c8f6ff",
	"brightYellow": "#ffff09",
	"communityColor": "#272eda",
	"darkGrey": "#1a1723",
	"darkGrey2": "#1f2024",
	"darkerGrey": "#242831",
	"darkerGreyAlpha": "rgba(20,16,30,.85)",
	"green": "#02c686",
	"greenDark": "#105e44",
	"greenMain": "#00b377",
	"lightGrey": "gray",
	"lightGrey2": "#454545",
	"lightGrey4": "#404040",
	"offBlack": "#a0a0a5",
	"purple": "#5922fe",
	"redMain": "#ff4949",
	"white": "#fff",
	"buyColor": "#4fc5bb",
	"sellColor": "#3953a7",
	"winColor": "#4fc5bb",
	"lossColor": "#fd542d",
	"black": "#000",
	"label": "_1iJLzGLdps1rsXtL43CVHy",
	"input": "_3k4oCuqFDgmG4KFOHkxDg3",
	"inputAdmin": "jzoBQVzIT-EA6MRH1Wfir"
};
module.exports = exports;
