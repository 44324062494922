import styles from './button.module.scss'
import React from 'react'

interface ButtonWrapper {
  onClick: () => void
  children: JSX.Element | string
  gradient?: boolean
  secondary?: boolean
  white?: boolean
  style?: Record<string, unknown>
  large?: boolean
  disabled?: boolean
  isLoading?: boolean
  className?: string
  collapse?: boolean
}

const ButtonWrapper = React.forwardRef<HTMLInputElement>(({
  onClick,
  gradient,
  secondary,
  white,
  children,
  large,
  style,
  disabled,
  isLoading,
  className,
  collapse,
}: ButtonWrapper,ref) => {
  if ((gradient && white) || (white && secondary)) {
    throw SyntaxError('Cannot use multiple styles')
  }

  return (
    <button
      className={`
        ${styles.btn}
        ${secondary ? styles.btnSecondary : ''}
        ${gradient ? styles.btnGradient : ''}
        ${white ? styles.btnWhite : ''}
        ${large ? styles.btnLarge : ''} 
        ${collapse ? styles.btnCollapse : ''}
        ${disabled ? styles.btnDisabled : ''}
        ${className ? className : ''}
        ${onClick ? styles.btnHover : ''}
      `}
      style={style || {}}
      onClick={
        !disabled
          ? onClick
          : () => {
              /* disabled do nothing */
            }
      }
      disabled={disabled}
      ref={ref}
    >
      <span
        className={`
          ${styles.btnLabel}
          ${secondary ? styles.btnLabelSecondary : ''}  
          ${gradient ? styles.btnLabelGradient : ''}  
          ${white ? styles.btnLabelWhite : ''}
          ${large ? styles.btnLabelLarge : ''}
          ${disabled ? styles.btnLabelDisabled : ''}
          ${onClick ? styles.btnLabelHover : ''}
        `}
      >
        {isLoading ? (
          <div
            className={`
          ${styles.loaderEllipsis}
          ${large ? styles.loaderLarge : styles.loaderSmall}
          `}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          children
        )}
      </span>
    </button>
  )
})

export default ButtonWrapper
