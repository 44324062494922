import { Dispatch, Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { fetchError } from '../error/actions'
import {
  failedToAddAccount,
  successfullyAddedAccount,
  successfullyRemovedAccount,
  storeAccountNumber,
  FailedToAddAccountI,
  SuccessfullyAddedAccountI,
  StoreAccountNumberI,
  forceReload,
} from '../globalActions'
import {CLOSE_ADD_ACCOUNT, 
  STORE_USER, 
  SET_SELECTED_ACCOUNT, 
  STORE_BROKERS, 
  STORE_BROKERS_SERVERS, 
  HISTORY_IS_NOT_LOADED, 
  HISTORY_IS_LOADED,
  POLLING_IN_PROGRESS } from './actionTypes'
import { Store as StoreState } from '../store'
import api from '../../api'
import { SetSelectedAccountNumber, StoreUser } from './userTypes'
import {
  getAssetsByPeriod,
  getAssetStats,
  getCashByPeriod,
  getGrowthByPeriod,
  getGrowthByPeriodGrouped,
  getTraderStats,
  getTradingDays,
} from '../metrics/actions'
import { getTradesDuration } from '../trades/actions'
import swal from 'sweetalert'
import {setAllDataLoaded} from '../metricsPeriod/actions'

const API = api()

export const getUser = (polling=false, acc_id, acc_no, status, process_stage): ThunkAction<
  void,
  StoreState,
  unknown,
  Action<StoreUser>
> => async (dispatch: Dispatch) => {
  try {
    const user = await API.userAndAccount()
    const user_data = { ...user[0].data, accounts: user[1].data.accounts }
    //add check to make sure this is the latest account
    
    if(localStorage.getItem('onReloadForMetrics')!='0'){
      if (user[1].data.length===1){
        localStorage.setItem('firstAccountLoaded', '1')
      }
      else{
        localStorage.setItem('firstAccountLoaded', '0')
      }
    }
    
    
    
    let account_id = user_data.accounts[0]?.account_id
    let history_loaded = user_data.accounts[0]?.history_loaded
    for (let i=0; i<user_data.accounts.length; i++)
    {
      if(user_data.accounts[i]?.account_name===null){
        account_id = user_data.accounts[i]?.account_id
        history_loaded = user_data.accounts[i]?.history_loaded
        break
      }

    }
    // let reload_key = parseInt((localStorage.getItem("reload_refresh_key")))
    dispatch({ type: STORE_USER, payload: user_data })
    dispatch({ type: HISTORY_IS_LOADED })
    if (polling)
    {
      dispatch({type:POLLING_IN_PROGRESS, payload:{account:acc_no, status:status, process_stage:process_stage}})
      const response = await PollAccounts(acc_id, dispatch)
    }
    
  } catch (err) {
    dispatch(failedToAddAccount(err.message))
    dispatch(fetchError(err))
  }
}
export const PollAccounts = async (account_id, dispatch) => {
  const loaded_account = await API.pollAccount({ account_id })
  var current_stage = loaded_account.data?.process_stage
  const status = await loaded_account.data?.status
  if ((current_stage === 5) || (current_stage === 6)){
    localStorage.setItem('firstAccountStage', 'true')
    dispatch(getUser())
    var accounts_poll = JSON.parse(localStorage.getItem('pollingAccounts'))
    var newPollData = []
    for (var i=0; i<accounts_poll.length;i++)
    {
        if(accounts_poll[i].id!=loaded_account.data.id){
          newPollData.push(accounts_poll[i])
        }
    }
    if (newPollData.length===0){
      localStorage.removeItem('pollingAccounts')
    }
    else{
      localStorage.setItem('pollingAccounts', JSON.stringify(newPollData))
    }
    current_stage === 5 ? dispatch(successfullyAddedAccount(status)) : dispatch(successfullyAddedAccount(status))
    current_stage === 5? dispatch({type:POLLING_IN_PROGRESS, payload:{account:loaded_account.data?.account_number, status:'Loading data', process_stage:5}}):''
    setTimeout(()=>{dispatch({type:POLLING_IN_PROGRESS, payload:{account:loaded_account.data?.account_number, status:loaded_account.data?.status, process_stage:loaded_account.data?.process_stage, remove:1}})})
    localStorage.setItem('fromAccount', 'true')
    
    current_stage === 5 ? setTimeout(()=>{
    dispatch(getTraderStats('all'))
    dispatch(getCashByPeriod('all', null, 'total'))
    dispatch(getGrowthByPeriodGrouped('all', null))
    dispatch(getAssetStats('all'))
    dispatch(getTradingDays('all'))

    // //for Monthly
    // dispatch(getTraderStats('monthly'))
    // dispatch(getCashByPeriod('monthly', null, 'total'))
    // dispatch(getGrowthByPeriodGrouped('monthly', null))
    // dispatch(getAssetStats('monthly'))
    // dispatch(getTradingDays('monthly'))

    // //for Weekly
    // dispatch(getTraderStats('weekly'))
    // dispatch(getCashByPeriod('weekly', null, 'total'))
    // dispatch(getGrowthByPeriodGrouped('weekly', null))
    // dispatch(getAssetStats('weekly'))
    // dispatch(getTradingDays('weekly'))
    // dispatch(getTradesDuration({page:1,type: 'closed',date_from:null,date_to:null}))
    // localStorage.removeItem('firstAccountStage')
    }, 2000):''
    setTimeout(()=>{current_stage === 5 ? dispatch(successfullyAddedAccount(status)) : dispatch(successfullyAddedAccount(status))},1000)
  } 
  else {
    dispatch({type:POLLING_IN_PROGRESS, payload:{account:loaded_account.data?.account_number, status:loaded_account.data?.status,process_stage:loaded_account.data?.process_stage}})
    setTimeout(() => {  PollAccounts(account_id, dispatch) }, 10000) 
  }
}


type AddAccountType =
  | FailedToAddAccountI
  | SuccessfullyAddedAccountI
  | StoreAccountNumberI

export const addAccount = (
  account_number: string,
  password: string,
  broker_id: number,
  broker_server_id:number,
  mt_version: number,
  allow_investor_sharing:boolean
): ThunkAction<void, StoreState, unknown, Action<AddAccountType | StoreUser>> => async (
  dispatch: Dispatch,
  getState: () => StoreState
) => {
    const current_accnos = getState().user.logged_in.accounts.map(
      (acc) => acc.account_number
    )

    const user_id = getState().user.logged_in.id

    let is_allready_added = false

    current_accnos.forEach((accno) => {
      if (accno === parseInt(account_number)) {
        is_allready_added = true
      }
    })
    try {
      if (is_allready_added) {
        //this code needs to be plugged in 
        swal({
          title: 'Account Add',
          text: 'This account is already connected.',
          icon: 'error',
          button: 'ok'
        })
        dispatch(failedToAddAccount('This account is already connected.'))
      } else {
        // the register process 
          const registration = await API.registerMT4({
          account_number,
          user_id,
          broker_server_id,
          broker_id,
          password,
          mt_version,
          allow_investor_sharing
        })       
        if (registration.response?.data?.message) {
          dispatch(failedToAddAccount(''))
          dispatch(fetchError(registration.response?.data?.message))
          swal({
            title: 'Add Account',
            text: registration.response?.data?.debug_message,
            icon: 'error',
            button: 'ok'
          })
        }

        else if (registration.error) {
          dispatch(failedToAddAccount(registration.message))
        } else {
          //register process successful we now do some accout bits
          dispatch({type:CLOSE_ADD_ACCOUNT})
          dispatch(successfullyAddedAccount('Account Verified.'))
          dispatch(forceReload())
          dispatch(storeAccountNumber(account_number))
          dispatch(getUser(true, registration?.data.id, registration?.data.account_number,registration?.data.status, registration?.data.process_stage))
          
          if(localStorage.getItem('pollingAccounts')===null){
            var pollAccountArray = []
            pollAccountArray.push(registration.data)
            localStorage.setItem('pollingAccounts', JSON.stringify(pollAccountArray))
          }
          else{
            var pollAccountAvailable = JSON.parse(localStorage.getItem('pollingAccounts'))
            pollAccountAvailable.push(registration.data)
            localStorage.setItem('pollingAccounts', JSON.stringify(pollAccountAvailable))
          }
          
        }
      }
    } catch (err) {
      dispatch(failedToAddAccount(err?.response?.data?.debug_message))
      dispatch(fetchError(err))
      
    }
  }

export const removeAccount = (
  accno: number,
  user_id: number
): ThunkAction<void, StoreState, unknown, Action<AddAccountType>> => async (
  dispatch: Dispatch,
) => {
    try {
      await API.removeAccount(accno, user_id)
      dispatch(successfullyRemovedAccount())
      dispatch(getUser())
    } catch (error) {
      dispatch(failedToAddAccount(error.message))
    }
  }

export const setSelectedAccount = (accno: string): SetSelectedAccountNumber => ({
  type: SET_SELECTED_ACCOUNT,
  payload: accno,
})

export const getBrokers = (mt_version:number, account_type:number): ThunkAction<
  void,
  StoreState,
  unknown,
  Action<AddAccountType>
> => async (dispatch: Dispatch) => {
  try {
    const { data } = await API.getBrokers(mt_version, account_type)
    dispatch({ type: STORE_BROKERS, payload: data.brokers })
  } catch (error) {
    dispatch(fetchError(error))
  }
}


export const getBrokerServers = (
  company: string, mt_version:number, account_type:number): ThunkAction<
  void,
  StoreState,
  unknown,
  Action<AddAccountType>
> => async (dispatch: Dispatch) => {
  try {
    const { data } = await API.getBrokerServers(company, mt_version, account_type)
    dispatch({ type: STORE_BROKERS_SERVERS, payload: data.servers })
    dispatch({ type: HISTORY_IS_LOADED })
  } catch (error) {
    dispatch(fetchError(error))
  }
}