export enum AccessLevel {
  user = 10,
  staff = 50,
  admin = 75,
  superuser = 99,
}

export enum UserStages {
  signed_up = 0,
  contact_confirmed,
  account_added,
  assigned,
  contacted,
  responded,
  not_interested,
  closed,
}
