import produce, { Draft, enableES5 } from 'immer'
import { SET_CURRENCY_PAIR, SET_DATE_FORMAT, SET_DATE_FROM, SET_DATE_TO, SET_TRADE_STATUS, SET_ORDER, SET_ASC_DESC, SET_NAME_FORMAT } from './actionTypes'
import { InitialState, AuthAction } from './superAdminFilterTypes'
import moment from 'moment'

enableES5()

export const initial_state: InitialState = {
  ascdesc:"desc",
  order:"timestamp",
  assets: [],
  date_from: null,
  date_to: null,
  trade_status:{
    closed: true,
  open: true,
  deposits: true,
  withdrawals: true,},
  date_format:false,
  name_format:0

}

export default produce((draft: Draft<InitialState>, action: AuthAction) => {
  switch (action.type) {
    case SET_CURRENCY_PAIR:
      draft.assets = action.payload ? action.payload : []
      break

      case SET_ORDER:
        draft.order = action.payload ? action.payload : []
        break

        case SET_ASC_DESC:
          draft.ascdesc = action.payload ? action.payload : []
          break

      case SET_TRADE_STATUS:
        draft.trade_status = action.payload ? action.payload : []
        break

        case SET_DATE_FORMAT:
        draft.date_format = action.payload ? action.payload : false
        break

        case SET_NAME_FORMAT:
        draft.name_format = action.payload ? action.payload : 0
        break

    case SET_DATE_FROM:
      draft.date_from = action.payload
        ? moment(action.payload).format('YYYY-MM-DD')
        : null
      break

    case SET_DATE_TO:
      draft.date_to = action.payload ? moment(action.payload).format('YYYY-MM-DD') : null
      break

    default:
      break
  }
}, initial_state)
