import produce, { Draft, enableES5 } from 'immer'
import { SET_CURRENCY_PAIR, SET_DATE_FROM, SET_DATE_TO } from './actionTypes'
import { InitialState, AuthAction } from './historyUiTypes'
import moment from 'moment'

enableES5()

export const initial_state: InitialState = {
  assets: null,
  date_from: null,
  date_to: null,
}

export default produce((draft: Draft<InitialState>, action: AuthAction) => {
  switch (action.type) {
    case SET_CURRENCY_PAIR:
      draft.assets = action.payload ? [action.payload] : null
      break

    case SET_DATE_FROM:
      draft.date_from = action.payload
        ? moment(action.payload).format('YYYY-MM-DD')
        : null
      break

    case SET_DATE_TO:
      draft.date_to = action.payload ? moment(action.payload).format('YYYY-MM-DD') : null
      break

    default:
      break
  }
}, initial_state)
