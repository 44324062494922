import { SALES_FILTER } from './type'

const INITIAL_STATE = null
const sales_filter_Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SALES_FILTER:
      return {
        ...state,
        sales_filter: action.payload,
      }
    default:
      return state
  }
}
export default sales_filter_Reducer
