import React, { useState, useEffect } from 'react'
import colors from '../../scss/_colors.scss'
import './loader.scss'
interface LoaderProps {
  admin?: boolean
}

const Loader = ({ admin }: LoaderProps) => {

  const [show, setShow] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])


  return (
    <div className="scene">
      {
        show &&
        <>
          <svg className="spinner" width="132" height="133" viewBox="0 0 132 133" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="65.9998" cy="66.145" r="56.4668" stroke="url(#paint0_linear_1_7145)" stroke-width="5" stroke-linecap="round" stroke-dasharray="20 20"/>
            <defs>
              <linearGradient id="paint0_linear_1_7145" x1="7.03127" y1="125.11" x2="124.965" y2="7.17653" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F0F4FA"/>
                <stop offset="1" stop-color="#4FC5BB"/>
              </linearGradient>
            </defs>
          </svg>
          <svg className='loading' width="132" height="133" viewBox="0 0 132 133" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.7879 69.2848C47.4722 69.2848 48.9039 69.8743 50.0829 71.0533C51.262 72.2323 51.8515 73.664 51.8515 75.3483C51.8515 77.0326 51.262 78.4643 50.0829 79.6433C48.9039 80.8224 47.4722 81.4119 45.7879 81.4119C44.1036 81.4119 42.6719 80.8224 41.4929 79.6433C40.3139 78.4643 39.7244 77.0326 39.7244 75.3483C39.7244 73.664 40.3139 72.2323 41.4929 71.0533C42.6719 69.8743 44.1036 69.2848 45.7879 69.2848ZM65.9998 69.2848C67.6841 69.2848 69.1157 69.8743 70.2948 71.0533C71.4738 72.2323 72.0633 73.664 72.0633 75.3483C72.0633 77.0326 71.4738 78.4643 70.2948 79.6433C69.1157 80.8224 67.6841 81.4119 65.9998 81.4119C64.3154 81.4119 62.8838 80.8224 61.7047 79.6433C60.5257 78.4643 59.9362 77.0326 59.9362 75.3483C59.9362 73.664 60.5257 72.2323 61.7047 71.0533C62.8838 69.8743 64.3154 69.2848 65.9998 69.2848ZM86.2116 69.2848C87.8959 69.2848 89.3276 69.8743 90.5066 71.0533C91.6856 72.2323 92.2751 73.664 92.2751 75.3483C92.2751 77.0326 91.6856 78.4643 90.5066 79.6433C89.3276 80.8224 87.8959 81.4119 86.2116 81.4119C84.5273 81.4119 83.0956 80.8224 81.9166 79.6433C80.7376 78.4643 80.148 77.0326 80.148 75.3483C80.148 73.664 80.7376 72.2323 81.9166 71.0533C83.0956 69.8743 84.5273 69.2848 86.2116 69.2848Z" fill="url(#paint1_linear_1_7145)"/>
            <path d="M44.8694 60.8779H40.0694V52.4779H41.2214V59.8459H44.8694V60.8779ZM52.7022 56.6779C52.7022 54.8179 51.3942 53.3659 49.6062 53.3659C47.8182 53.3659 46.5102 54.8179 46.5102 56.6779C46.5102 58.5379 47.8182 59.9899 49.6062 59.9899C51.3942 59.9899 52.7022 58.5379 52.7022 56.6779ZM53.9262 56.6779C53.9262 59.0779 52.0542 61.0219 49.6062 61.0219C47.1582 61.0219 45.2862 59.0779 45.2862 56.6779C45.2862 54.2779 47.1582 52.3339 49.6062 52.3339C52.0542 52.3339 53.9262 54.2779 53.9262 56.6779ZM62.0659 60.8779H60.7339L60.0739 59.0899H56.4979L55.8499 60.8779H54.5899L57.8419 52.4779H58.8499L62.0659 60.8779ZM58.2859 54.2299L56.8819 58.0579H59.7019L58.2979 54.2299H58.2859ZM63.4483 60.8779V52.4779H65.7163C67.8523 52.4779 70.2283 53.6059 70.2283 56.6419C70.2283 59.7499 67.8763 60.8779 65.5963 60.8779H63.4483ZM69.0043 56.7499C69.0043 54.6259 67.7083 53.5099 65.5003 53.5099H64.6003V59.8459H64.9963C67.3963 59.8459 69.0043 59.0899 69.0043 56.7499ZM73.2371 60.8779H72.0851V52.4779H73.2371V60.8779ZM82.2987 60.8779H81.2787L76.6707 54.6139V60.8779H75.5187V52.4779H76.5267L81.1467 58.7419V52.4779H82.2987V60.8779ZM90.9613 57.2539H88.8253V56.2219H92.1133V59.4139C91.3093 60.4339 90.0493 61.0219 88.6693 61.0219C86.1013 61.0219 84.1573 59.2819 84.1573 56.6779C84.1573 54.1939 86.1853 52.3339 88.6693 52.3339C89.9653 52.3339 91.1173 52.9099 91.9213 53.8219L91.1053 54.4939C90.5173 53.8339 89.6293 53.3659 88.6693 53.3659C86.7733 53.3659 85.3813 54.7579 85.3813 56.6779C85.3813 58.7179 86.7493 59.9899 88.6693 59.9899C89.5573 59.9899 90.3733 59.6779 90.9613 59.1139V57.2539Z" fill="#B2BDCF">
              <animate
                  attributeName="opacity"
                  values="0;1;0"
                  dur="1.8s"
                  repeatCount="indefinite"
              />
            </path>
            <defs>
              <linearGradient id="paint1_linear_1_7145" x1="39.7236" y1="69.2849" x2="45.0377" y2="92.3128" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F0F4FA"/>
                <stop offset="1" stop-color="#4FC5BB"/>
              </linearGradient>
            </defs>
          </svg>
        </>
        } 
    </div>
  )
}

export default Loader
