import styles from './text.module.scss'
import React from 'react'

interface IText {
  unchanged?:boolean
  size: 'head1' | 'head2' | 'head3' | 'head5' | 'body1'
  color?: 'Grey' | 'White' | 'Red' | 'Green' | 'Black' | 'Purple'
  bold?: boolean
  suffix?: JSX.Element
  prefix?: JSX.Element
  value: string | number
  className?: string
  margin?: boolean
  onClick?: () => void
  active?: boolean
  textBlink?:boolean
  centered?:boolean
  fontSize?:number
}

const Text = ({
  size,
  color,
  bold,
  suffix,
  prefix,
  value,
  className,
  margin,
  onClick,
  active,
  textBlink,
  centered,
  fontSize,
}: IText) => {
  return (
<>
    {
      <span
      className={`
        ${styles.text}
        ${color ? styles[`text${color}`] : ''} 
        ${(size && !bold)? styles[`text${size}`] : ''}
        ${(size && bold )? styles[`text${size}Bold`] : ''}
        ${className || ''} 
        ${bold ? styles.textBold : ''}
        ${centered ? styles.textCentered : ''}
        ${margin ? styles.textMargin : ''}
        ${active ? styles.textActive : ''}
      `}
      onClick={onClick ? onClick : null}
      style={onClick ? { cursor: 'pointer',fontSize } : {fontSize}}
    >
      <span className={styles.textPrefix}>{prefix}</span>
       {textBlink?<span className={styles.flash}>{value}</span>:<span>{value}</span>}
      <span className={styles.textSuffix}>{suffix}</span>
    </span>
    }

   </> 
  )
}
export default Text
