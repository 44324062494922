import produce, { Draft, enableES5 } from 'immer'
import {
  STORE_TRADES_PAGINATION,
  STORE_TRADES,
  STORE_DEPOSIT_WITHDRAWALS,
  STORE_DEPOSIT_WITHDRAWALS_LOADED,
  STORE_DEPOSIT_WITHDRAWAL_PAGINATION,
  STORE_NOTES,
  STORE_TRADES_OPEN,
  STORE_TRADES_DURATION,
  STORE_TRADES_DURATION_ALL,
  STORE_TRADES_DURATION_MONTHLY,
  STORE_TRADES_DURATION_WEEKLY
} from './actionTypes'
import { InitialState, AuthAction } from './tradesTypes'

enableES5()

export const initial_state: InitialState = {
  page_of_trades: [],
  closed_trades:[],
  trades_pagination: {
    page: 0,
    per_page: 0,
    page_count: 0,
    item_count: 0,
  },
  deposits_and_withdrawals_loaded: false,
  deposits_and_withdrawals: [],
  deposits_and_withdrawals_pagination: {
    page: 0,
    per_page: 0,
    page_count: 0,
    item_count: 0,
  },
  notes: {},
  trade_duration_data: [],
  trade_duration_data_all: [],
  trade_duration_data_weekly: [],
  trade_duration_data_monthly: []
}

export default produce((draft: Draft<InitialState>, action: AuthAction) => {
  switch (action.type) {
    case STORE_TRADES_PAGINATION:
      draft.trades_pagination = action.payload
      break

    case STORE_TRADES_DURATION:
      if(action.payload_all)
          draft.trade_duration_data = action.payload_all.trade_duration_data_all
      else if(action.payload_monthly)
          draft.trade_duration_data = action.payload_monthly.trade_duration_data_monthly
      else if(action.payload_weekly)
          draft.trade_duration_data = action.payload_weekly.trade_duration_data_weekly
      else 
          draft.trade_duration_data = action.payload.trades
      break

    case STORE_TRADES_DURATION_ALL:
      draft.trade_duration_data_all = action.payload.trades
      break

    case STORE_TRADES_DURATION_MONTHLY:
      draft.trade_duration_data_monthly= action.payload.trades
      break

    case STORE_TRADES_DURATION_WEEKLY:
      draft.trade_duration_data_weekly = action.payload.trades
      break

    case STORE_TRADES:
      draft.page_of_trades = action.payload.trades
      break

    case STORE_DEPOSIT_WITHDRAWALS:
      draft.deposits_and_withdrawals = action.payload.trades
      break

    case STORE_DEPOSIT_WITHDRAWAL_PAGINATION:
      draft.deposits_and_withdrawals_pagination = action.payload
      break
    case STORE_NOTES:
      // eslint-disable-next-line no-case-declarations
      const { ticket, notes } = action.payload
      draft.notes[ticket] = notes
      break

    case STORE_DEPOSIT_WITHDRAWALS_LOADED:
      draft.deposits_and_withdrawals_loaded = true
      break

    case STORE_TRADES_OPEN:
      draft.open_trades = action.payload.trades
      break
    default:
      break
  }
}, initial_state)
