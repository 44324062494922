import { useRef } from 'react'
import PubNub from 'pubnub'

const uuid = PubNub.generateUUID()
const memberuuid = PubNub.generateUUID()

const usePubnub = () => {
  
  const pubnubRef = useRef(
    new PubNub({
      publishKey: 'pub-c-f4443ee2-4122-4f2d-9a03-d969e2f5ec3d',
      subscribeKey: 'sub-c-99051d90-b70f-11eb-b2e5-0e040bede276',
      uuid: uuid
    })
  )
  const pubnub = pubnubRef.current
  return pubnub
}
export const useMemberPubnub = () => {
  const pubnubRef = useRef(
    new PubNub({
      publishKey: 'pub-c-3e95c801-a5b7-4fc1-81af-fe491bf8b4ba',
      subscribeKey: 'sub-c-9d4f373c-e324-11eb-aa54-22f598fbfd18',
      uuid: memberuuid
    })
  )
  const pubnub = pubnubRef.current

  return pubnub
}

export default usePubnub

