export const STORE_CASH_BY_PERIOD = 'user/store-cash-by-period'
export const STORE_PIP_BY_PERIOD = 'user/store-pips-by-period'
export const STORE_STATS = 'user/store-stats'
export const STORE_ASSET_STATS = 'user/store-asset-stats'
export const STORE_ACCOUNT_NUMBER = 'user/store-account-number'
export const NO_STATS_AVAILABLE = 'user/no-stats-available'
export const STORE_TOP_TRADING_DAYS = 'user/store-top-trading-days'

export const STORE_AGG_CASH_BY_PERIOD = 'user/store-agg-cash-by-period'
export const STORE_AGG_PIP_BY_PERIOD = 'user/store-agg-pip-by-period'

export const STORE_GROWTH_BY_PERIOD = 'user/store-growth-by-period'
export const STORE_AGG_GROWTH_BY_PERIOD = 'user/store-agg-growth-by-period'
export const STORE_GROWTH_BY_PERIOD_GROUPED = 'user/store-growth-by-period-grouped'

export const ALL_LOADED = 'ALL_LOADED'

export const STORE_CASH_BY_PERIOD_ALL = 'user/store-cash-by-period-all'
export const STORE_PIP_BY_PERIOD_ALL = 'user/store-pips-by-period-all'
export const STORE_STATS_ALL = 'user/store-stats-all'
export const STORE_ASSET_STATS_ALL = 'user/store-asset-stats-all'
export const STORE_TOP_TRADING_DAYS_ALL = 'user/store-top-trading-days-all'

export const STORE_AGG_CASH_BY_PERIOD_ALL = 'user/store-agg-cash-by-period-all'
export const STORE_AGG_PIP_BY_PERIOD_ALL = 'user/store-agg-pip-by-period-all'

export const STORE_GROWTH_BY_PERIOD_ALL = 'user/store-growth-by-period-all'
export const STORE_AGG_GROWTH_BY_PERIOD_ALL = 'user/store-agg-growth-by-period-all'
export const STORE_GROWTH_BY_PERIOD_GROUPED_ALL = 'user/store-growth-by-period-grouped-all'