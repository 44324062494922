import produce, { Draft, enableES5 } from 'immer'
import {
  STORE_CASH_BY_PERIOD,
  STORE_PIP_BY_PERIOD,
  STORE_AGG_CASH_BY_PERIOD,
  STORE_AGG_PIP_BY_PERIOD,
  STORE_STATS,
  NO_STATS_AVAILABLE,
  STORE_ASSET_STATS,
  STORE_TOP_TRADING_DAYS,
  STORE_GROWTH_BY_PERIOD,
  STORE_AGG_GROWTH_BY_PERIOD,
  STORE_GROWTH_BY_PERIOD_GROUPED,

  STORE_CASH_BY_PERIOD_ALL,
  STORE_PIP_BY_PERIOD_ALL,
  STORE_AGG_CASH_BY_PERIOD_ALL,
  STORE_AGG_PIP_BY_PERIOD_ALL,
  STORE_STATS_ALL,
  STORE_ASSET_STATS_ALL,
  STORE_TOP_TRADING_DAYS_ALL,
  STORE_GROWTH_BY_PERIOD_ALL,
  STORE_AGG_GROWTH_BY_PERIOD_ALL,
  STORE_GROWTH_BY_PERIOD_GROUPED_ALL,

} from './actionTypes'

import { FORCE_RELOAD } from '../globalActions/actionTypes'

import { InitialState, MetricsActions } from './metricsTypes'

enableES5()

export const initial_state: InitialState = {
  currency: 'GBP',
  balance: 0,
  cash_by_period: [],
  cash_agg_by_period: [],
  pips_by_period: [],
  pips_agg_by_period: [],
  growth_by_period: [],
  growth_agg_by_period: [],
  growth_by_period_grouped: [],
  stats: {
    account_number: null,
    buy: null,
    closed: null,
    deposit: null,
    duration_avg: null,
    gain: null,
    loss: null,
    loss_duration_avg: null,
    loss_pnl_cash_avg: null,
    loss_pnl_pips_avg: null,
    open_pnl_cash: null,
    open_pnl_pips: null,
    opened: null,
    opening_balance: '',
    pnl_cash: null,
    pnl_pips: null,
    probability: null,
    sell: null,
    sl_hit_perc: null,
    toxic_perc: null,
    tp_hit_perc: null,
    volume: null,
    win: null,
    win_duration_avg: null,
    win_pnl_cash_avg: null,
    win_pnl_pips_avg: null,
    withdrawal: null,
    win_duration_sum:null,
    loss_duration_sum:null,
    win_duration_max:null,
    loss_duration_max:null
  },
  top_assets: [],
  all_assets_traded: [],
  top_trading_days: [],
  all_data_loaded: false,
  data_loaded_flag: 10,
  data_cur_loaded: 0,
  unique_force_reload_key: '',

  balance_all: 0,
  cash_by_period_all: [],
  cash_agg_by_period_all: [],
  pips_by_period_all: [],
  pips_agg_by_period_all: [],
  growth_by_period_all: [],
  growth_agg_by_period_all: [],
  growth_by_period_grouped_all: [],
  stats_all: {
    account_number: null,
    buy: null,
    closed: null,
    deposit: null,
    duration_avg: null,
    gain: null,
    loss: null,
    loss_duration_avg: null,
    loss_pnl_cash_avg: null,
    loss_pnl_pips_avg: null,
    open_pnl_cash: null,
    open_pnl_pips: null,
    opened: null,
    opening_balance: '',
    pnl_cash: null,
    pnl_pips: null,
    probability: null,
    sell: null,
    sl_hit_perc: null,
    toxic_perc: null,
    tp_hit_perc: null,
    volume: null,
    win: null,
    win_duration_avg: null,
    win_pnl_cash_avg: null,
    win_pnl_pips_avg: null,
    withdrawal: null,
    win_duration_sum:null,
    loss_duration_sum:null,
    win_duration_max:null,
    loss_duration_max:null
  },
  top_assets_all: [],
  all_assets_traded_all: [],
  top_trading_days_all: [],
  all_data_loaded_all: false,
  data_loaded_flag_all: 10,
  data_cur_loaded_all: 0,
  unique_force_reload_key_all: '',

  balance_monthly: 0,
  cash_by_period_monthly: [],
  cash_agg_by_period_monthly: [],
  pips_by_period_monthly: [],
  pips_agg_by_period_monthly: [],
  growth_by_period_monthly: [],
  growth_agg_by_period_monthly: [],
  growth_by_period_grouped_monthly: [],
  stats_monthly: {
    account_number: null,
    buy: null,
    closed: null,
    deposit: null,
    duration_avg: null,
    gain: null,
    loss: null,
    loss_duration_avg: null,
    loss_pnl_cash_avg: null,
    loss_pnl_pips_avg: null,
    open_pnl_cash: null,
    open_pnl_pips: null,
    opened: null,
    opening_balance: '',
    pnl_cash: null,
    pnl_pips: null,
    probability: null,
    sell: null,
    sl_hit_perc: null,
    toxic_perc: null,
    tp_hit_perc: null,
    volume: null,
    win: null,
    win_duration_avg: null,
    win_pnl_cash_avg: null,
    win_pnl_pips_avg: null,
    withdrawal: null,
    win_duration_sum:null,
    loss_duration_sum:null,
    win_duration_max:null,
    loss_duration_max:null
  },
  top_assets_monthly: [],
  all_assets_traded_monthly: [],
  top_trading_days_monthly: [],
  all_data_loaded_monthly: false,
  data_loaded_flag_monthly: 10,
  data_cur_loaded_monthly: 0,
  unique_force_reload_key_monthly: '',

  balance_weekly: 0,
  cash_by_period_weekly: [],
  cash_agg_by_period_weekly: [],
  pips_by_period_weekly: [],
  pips_agg_by_period_weekly: [],
  growth_by_period_weekly: [],
  growth_agg_by_period_weekly: [],
  growth_by_period_grouped_weekly: [],
  stats_weekly: {
    account_number: null,
    buy: null,
    closed: null,
    deposit: null,
    duration_avg: null,
    gain: null,
    loss: null,
    loss_duration_avg: null,
    loss_pnl_cash_avg: null,
    loss_pnl_pips_avg: null,
    open_pnl_cash: null,
    open_pnl_pips: null,
    opened: null,
    opening_balance: '',
    pnl_cash: null,
    pnl_pips: null,
    probability: null,
    sell: null,
    sl_hit_perc: null,
    toxic_perc: null,
    tp_hit_perc: null,
    volume: null,
    win: null,
    win_duration_avg: null,
    win_pnl_cash_avg: null,
    win_pnl_pips_avg: null,
    withdrawal: null,
    win_duration_sum:null,
    loss_duration_sum:null,
    win_duration_max:null,
    loss_duration_max:null
  },
  top_assets_weekly: [],
  all_assets_traded_weekly: [],
  top_trading_days_weekly: [],
  all_data_loaded_weekly: false,
  data_loaded_flag_weekly: 10,
  data_cur_loaded_weekly: 0,
  unique_force_reload_key_weekly: '',
  all_loaded:false,
  all_current_loaded:0
}

function isLoaded(draft) {
  draft.data_cur_loaded++
  draft.all_data_loaded = draft.data_cur_loaded >= draft.data_loaded_flag
}
function isAllLoaded(draft){
  draft.all_current_loaded++
  draft.all_loaded = draft.all_current_loaded>=10

      
  

}
export default produce((draft: Draft<InitialState>, action: MetricsActions) => {
  switch (action.type) {
    

    case STORE_CASH_BY_PERIOD:
      if(action.payload_all){
        draft.balance = action.payload_all.balance_all
        draft.cash_by_period = action.payload_all.cash_by_period_all
      }
      else if(action.payload_monthly)
      {
        draft.balance = action.payload_monthly.balance_monthly
        draft.cash_by_period = action.payload_monthly.cash_by_period_monthly
      }
      else if(action.payload_weekly)
      {
        draft.balance = action.payload_weekly.balance_weekly
      draft.cash_by_period = action.payload_weekly.cash_by_period_weekly
      }
      else{
        draft.balance = action.payload.balance
      draft.cash_by_period = action.payload.total.map((dataPoint) => ({
        ...dataPoint,
        pnl_cash: dataPoint.pnl_cash,
      }))
      }
      
      isLoaded(draft)
      break

    case STORE_PIP_BY_PERIOD:
      if(action.payload_all){
        draft.pips_by_period = action.payload_all.pips_by_period_all
      }
      else if(action.payload_monthly){
        draft.pips_by_period = action.payload_monthly.pips_by_period_all
      }
      else if(action.payload_weekly)
      {
        draft.pips_by_period = action.payload_weekly.pips_by_period_all
      }
      else{
        draft.pips_by_period = action.payload.total.map((dataPoint) => ({
          ...dataPoint,
          pnl_pips: dataPoint.pnl_pips,
        }))
      }
      
      isLoaded(draft)
      break

    case STORE_GROWTH_BY_PERIOD:
      if(action.payload_all){
        draft.growth_by_period = action.payload_all.growth_by_period_all
      }
      else if(action.payload_monthly){
        draft.growth_by_period = action.payload_monthly.growth_by_period_monthly
      }
      else if(action.payload_weekly)
      {
        draft.growth_by_period = action.payload_weekly.growth_by_period_weekly
        
      }
      else{
        draft.growth_by_period = action.payload.total
      }
      
      isLoaded(draft)
      break

    case STORE_AGG_CASH_BY_PERIOD:   
    if(action.payload_all){
      draft.cash_agg_by_period = action.payload_all.cash_agg_by_period_all
    }
    else if(action.payload_monthly){
      draft.cash_agg_by_period = action.payload_monthly.cash_agg_by_period_monthly
    }
    else if(action.payload_weekly)
    {
      draft.cash_agg_by_period = action.payload_weekly.cash_agg_by_period_weekly
    }  
    else{
      const{delta} = action.payload
     
      draft.cash_agg_by_period = delta.map((dataPoint) => ({
        ...dataPoint,
        pnl_cash: dataPoint.pnl_cash,
      }))
    }
      

      isLoaded(draft)
      break

    case STORE_AGG_PIP_BY_PERIOD:
      if(action.payload_all){
        draft.pips_agg_by_period = action.payload_all.pips_agg_by_period_all
      }
      else if(action.payload_monthly){
        draft.pips_agg_by_period = action.payload_monthly.pips_agg_by_period_monthly
      }
      else if(action.payload_weekly)
      {
        draft.pips_agg_by_period = action.payload_weekly.pips_agg_by_period_weekly
      }
      else{
        draft.pips_agg_by_period = action.payload.delta.map((dataPoint) => ({
          ...dataPoint,
          pnl_pips: dataPoint.pnl_pips,
        }))
        
      }
      isLoaded(draft)
      break

    case STORE_AGG_GROWTH_BY_PERIOD:
      if(action.payload_all){
        draft.growth_agg_by_period = action.payload_all.growth_agg_by_period_all
      }
      else if(action.payload_monthly){
        draft.growth_agg_by_period = action.payload_monthly.growth_agg_by_period_monthly
      }
      else if(action.payload_weekly)
      {
        draft.growth_agg_by_period = action.payload_weekly.growth_agg_by_period_weekly
      }
      else{      
        draft.growth_agg_by_period = action.payload.delta
      }
        isLoaded(draft)
      break

    case STORE_GROWTH_BY_PERIOD_GROUPED:
      if(action.payload_all){
        draft.growth_by_period_grouped = action.payload_all.growth_by_period_grouped_all
      }
      else if(action.payload_monthly){
        draft.growth_by_period_grouped = action.payload_monthly.growth_by_period_grouped_monthly
      }
      else if(action.payload_weekly)
      {
        draft.growth_by_period_grouped = action.payload_weekly.growth_by_period_grouped_weekly
      }
      else{
        draft.growth_by_period_grouped = action.payload.delta
      }
      isLoaded(draft)
      break

    case STORE_STATS:
      if(action.payload_all){
        draft.stats = action.payload_all.stats_all
      }
      else if(action.payload_monthly){
        draft.stats = action.payload_monthly.stats_monthly

      }
      else if(action.payload_weekly)
      {
        draft.stats = action.payload_weekly.stats_weekly
      }
      else{
        draft.stats = action.payload.stats
        draft.stats.gain = (() => {
          const o = draft.stats.opening_balance
          const d = draft.stats.deposit
          const p = draft.stats.pnl_cash
          const a = o + d + p - (o + d)
          const gain = (a / (o + d)) * 100
          return gain
        })()
      }
      
      isLoaded(draft)
      
      break

    case NO_STATS_AVAILABLE:
      if(action.payload_all)
        draft.stats_all = { ...initial_state.stats_all, account_number: draft.stats_all.account_number }
      else if(action.payload_monthly)
        draft.stats_monthly = { ...initial_state.stats_monthly, account_number: draft.stats_monthly.account_number }
      else if(action.payload_weekly)
        draft.stats_weekly = { ...initial_state.stats_weekly, account_number: draft.stats_weekly.account_number }
        else if(action.payload)
        draft.stats = { ...initial_state.stats_all, account_number: draft.stats_all.account_number }
      break

    case STORE_ASSET_STATS:
      if(action.payload_all){
        draft.all_assets_traded = action.payload_all.all_assets_traded_all
        draft.top_assets = action.payload_all.top_assets_all
      }
      else if(action.payload_monthly){
        draft.all_assets_traded = action.payload_monthly.all_assets_traded_monthly
        draft.top_assets = action.payload_monthly.top_assets_monthly
      }
      else if(action.payload_weekly)
      {
        draft.all_assets_traded = action.payload_weekly.all_assets_traded_weekly
        draft.top_assets = action.payload_weekly.top_assets_weekly
      }
      else{
        draft.all_assets_traded = action.payload
        draft.top_assets = action.payload
        .sort((asset, asset2) => (asset2.total_losing_trades+asset2.total_winning_trades) - (asset.total_losing_trades+asset.total_winning_trades))
          .slice(0, 6)

      }
      
      isLoaded(draft)
      break

    case STORE_TOP_TRADING_DAYS:
      if(action.payload_all){
        draft.top_trading_days = action.payload_all.top_trading_days_all
      }
      else if(action.payload_monthly){
        draft.top_trading_days = action.payload_monthly.top_trading_days_monthly
      }
      else if(action.payload_weekly)
      {
        draft.top_trading_days = action.payload_weekly.top_trading_days_weekly
      }
      else{
        draft.top_trading_days = action.payload
      }
      
      isLoaded(draft)
      break

    case FORCE_RELOAD:
      localStorage.setItem('fromAccount', 'false')
      draft.unique_force_reload_key = action.payload
      break

    //**For ALL */

    case STORE_CASH_BY_PERIOD_ALL:
     
      draft.balance_all = action.payload.balance
      draft.cash_by_period_all = action.payload.total.map((dataPoint) => ({
        ...dataPoint,
        pnl_cash: dataPoint.pnl_cash,
      }))
      isAllLoaded(draft)
      break

    case STORE_PIP_BY_PERIOD_ALL:
      draft.pips_by_period_all = action.payload.total.map((dataPoint) => ({
        ...dataPoint,
        pnl_pips: dataPoint.pnl_pips,
      }))
      isAllLoaded(draft)
      break

    case STORE_GROWTH_BY_PERIOD_ALL:
      draft.growth_by_period_all = action.payload.total
       isAllLoaded(draft)
      break

    case STORE_AGG_CASH_BY_PERIOD_ALL:     
      // const{delta_all} = action.payload
     
      draft.cash_agg_by_period_all = action.payload.delta.map((dataPoint) => ({
        ...dataPoint,
        pnl_cash: dataPoint.pnl_cash,
      }))

      isAllLoaded(draft)
      break

    case STORE_AGG_PIP_BY_PERIOD_ALL:
      draft.pips_agg_by_period_all = action.payload.delta.map((dataPoint) => ({
        ...dataPoint,
        pnl_pips: dataPoint.pnl_pips,
      }))
      isAllLoaded(draft)
      break

    case STORE_AGG_GROWTH_BY_PERIOD_ALL:
      draft.growth_agg_by_period_all = action.payload.delta
      isAllLoaded(draft)
      break

    case STORE_GROWTH_BY_PERIOD_GROUPED_ALL:
      draft.growth_by_period_grouped_all = action.payload.delta
      isAllLoaded(draft)
      break

    case STORE_STATS_ALL:
      draft.stats_all = action.payload.stats
      draft.stats_all.gain = (() => {
        const o = draft.stats_all.opening_balance
        const d = draft.stats_all.deposit
        const p = draft.stats_all.pnl_cash
        const a = o + d + p - (o + d)
        const gain = (a / (o + d)) * 100
        return gain
      })()
      isAllLoaded(draft)
      
      break

    case STORE_ASSET_STATS_ALL:
      draft.all_assets_traded_all = action.payload
      draft.top_assets_all = action.payload
        .sort((asset, asset2) => (asset2.total_losing_trades+asset2.total_winning_trades) - (asset.total_losing_trades+asset.total_winning_trades))
        .slice(0, 6)
        isAllLoaded(draft)
      break

    case STORE_TOP_TRADING_DAYS_ALL:
      draft.top_trading_days_all = action.payload
      isAllLoaded(draft)
      break

    default:
      break
  }
}, initial_state)
